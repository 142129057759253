/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../GameList/gameList.css'
import { InfoTournamentSoccer } from '../../../../../api/homePage/homePageApi';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import { checkNoData } from '../../../../../until';
import { LogoWithoutText, video, redCard } from '../../../../../components/imgExport';
import useDebounce from '../../../../../hook/useDebounce';
import io from 'socket.io-client';
import { API_URL_SOCCER } from '../../../../../env';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useThrottle from '../../../../../hook/useThrottle';
import LazyLoad from 'react-lazyload';
import SoccerTopLiveV3New from './SoccerTopLiveV3New';
// function arraysEqual<T>(array1: T[], array2: T[]): boolean {
//     if (array1.length !== array2.length) {
//         return false;
//     }
//     for (let i = 0; i < array1.length; i++) {
//         if (array1[i] !== array2[i]) {
//             return false;
//         }
//     }
//     return true;
// }

interface GameSoccerProps {
    tournament: string
    item: InfoTournamentSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]

    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeScore?: number, awayScore?: number
    ) => void
    countTotalSoccer?: (
        count: number,
        tournament?: string
    ) => void
    checkUpdateOdds?: (id: number, point: number, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number, color?: string) => void
    blockGameID?: (id: number | string) => void
    listGameMyMatch: any[]
}
const MyMatchGameSoccerLive: React.FC<GameSoccerProps> = ({
    tournament, item, listChooseBet, listAddStrightBetsSlipSoccer,
    chooseBetSoccer, countTotalSoccer, checkUpdateOdds, checkUpdateStrightBetsSlip,
    blockGameID, listGameMyMatch

}) => {
    const pushToast = useToast();

    const [listGameSoccer, setListGameSoccer] = useState<homePage_V2Api.InfoGameSoccer[]>([])
    const [socket, setSocket] = useState<any>(null);
    const [showMoreBet, setShowMoreBet] = useState(false)
    const gGameSoccerByTournamentName = useDebounce(async () => {
        const ketQua = await homePage_V2Api.getGameSoccerLiveByTournamentName(item.tournament);
        if (ketQua?.success) {
            countTotalSoccer?.(ketQua.data.length, item.tournament)
            setListGameSoccer([]);
            const updateListFilter = ketQua.data.filter((bet: any) => (listGameMyMatch.includes(bet.id.toString())))
            const updateList = updateListFilter.map((item) => ({
                // const updateList = ketQua.data.map((item) => ({
                ...item,
                time: Number((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60) > 0 ?
                    ((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60).toFixed(0)
                    :
                    (60 - Number(Math.abs((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60)) % 60).toFixed(0)
            }))
            setListGameSoccer(updateList)
        } else {
            setListGameSoccer([]);
            pushToast(ketQua?.message, "warn");
        }
    }, 1000)

    useEffect(() => {
        gGameSoccerByTournamentName()
    }, [])

    useEffect(() => {
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);
        socketInstance.on('connect', () => {
            console.log('Connected to server live');
        });
        socketInstance.on('message-soccer', async (data) => {

            const updateList = data.filter((bet: any) => (bet.tournament.tournamentName === tournament && listGameMyMatch.includes(bet.id.toString()))).map((item: any) => ({
                ...item,
                time: Number((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60) > 0 ?
                    ((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60).toFixed(0)
                    :
                    (60 - Number(Math.abs((new Date(new Date().toLocaleString('en', { timeZone: 'America/Chicago' })).getTime() - new Date(new Date(item.mainMarketEventResult.extraInfo.periodStartTime).toLocaleString('en', { timeZone: 'America/Chicago' })).getTime()) / 1000 / 60)) % 60).toFixed(0)
                ,
                oddsOld: listGameSoccer.filter((betOld: any) => (betOld.id === item.id))[0]?.odds,
            }))
            await updateList.map((item: any) => {
                var SubbetTotalCorners = item.odds.filter((itemType: any) => (itemType.marketType === "TotalCorners_FH_OverUnder" || itemType.marketType === "TotalCorners_FH_Handicap" || itemType.marketType === "TotalCorners_OverUnder" || itemType.marketType === "TotalCorners_Handicap"))
                var Subbet = item.odds.filter((itemType: any) => (itemType.marketType === "OddEven" || itemType.marketType === "FH_CorrectScore" || itemType.marketType === "TotalGoal" || itemType.marketType === "DoubleChance" || itemType.marketType === "CorrectScore"))
                var filterSubbet: any[] = Array.from(new Map(Subbet.map((item: any) => [item.marketType, item])).values());
                item.countNumberSubbet = SubbetTotalCorners.length ? filterSubbet.length + 1 : filterSubbet.length
            })
            setListGameSoccer(updateList)

        });

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);

    const blockGame = useThrottle(() => {
        const listId = (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0) ? listAddStrightBetsSlipSoccer.map(ItemStraight => ItemStraight.idGame) : [];
    }, 1000)

    useEffect(() => {
        if (listChooseBet.length > 0 || (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0)) {
            blockGame()
        }
    }, [listGameSoccer])



    return (
        <>

            {
                listGameSoccer && listGameSoccer.map((item, i) =>
                    <LazyLoad height={20} key={i} className="xs:hover:bg-transparentnone md:hover:bg-[#181c2a]">
                        <div key={i} >
                            <div className='flex w-full border-b border-b-[#1D212D] pt-[5px]'>
                                <div className="lg:w-[140px] xs:hidden md:block">
                                    <div className="flex flex-col gap-1 ">
                                        <div className="w-[140px] flex flex-col items-start gap-0 pr-1">
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-start'>{item.homeTeam.teamName}</span>

                                                </div>
                                            </div>
                                            {item.mainMarketEventResult.extraInfo.homeRedCards ?
                                                <div className='flex items-center justify-center gap-2'>
                                                    <div className='w-[30px] h-[30px] flex justify-center items-center flex-shrink-0'>
                                                    </div>
                                                    <span className='flex gap-1 text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-start'>
                                                        {item.mainMarketEventResult.extraInfo.homeRedCards >= 2 ? item.mainMarketEventResult.extraInfo.homeRedCards : ""}
                                                        < img src={redCard} alt="NYKnicks" className='w-4 h-4' />
                                                    </span>
                                                </div>
                                                :
                                                ""
                                            }
                                            <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-start'>{item.awayTeam.teamName}</span>

                                                </div>
                                            </div>

                                            {item.mainMarketEventResult.extraInfo.awayRedCards ?
                                                <div className='flex items-center justify-center gap-2'>
                                                    <div className='w-[30px] h-[30px] flex justify-center items-center flex-shrink-0'>
                                                    </div>
                                                    <span className='flex gap-1 text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-center'>
                                                        {item.mainMarketEventResult.extraInfo.awayRedCards >= 2 ? item.mainMarketEventResult.extraInfo.awayRedCards : ""}
                                                        < img src={redCard} alt="NYKnicks" className='w-4 h-4' />
                                                    </span>
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="w-[100px] flex flex-col items-start gap-2 p-3 pl-1">
                                            <div>
                                                <img src={video} alt="" />
                                            </div>
                                            <span className='fw-[500] text-sm text-[#FFFFFF] whitespace-nowrap w-full text-start'>
                                                {item.mainMarketEventResult.extraInfo.period === 5 ? 'HT' : item.mainMarketEventResult.extraInfo.period === 0 ? 'LIVE' : (item.mainMarketEventResult.extraInfo.period + 'H ' + (item.time % 60 === 59 ? "0" : item.time % 60) + "'")}
                                            </span>
                                            {item.mainMarketEventResult.extraInfo.injuryTime !== 0 ?
                                                <span className="fw-[500] text-sm text-[#FFFFFF] -ml-1 whitespace-nowrap">
                                                    {" +" + item.mainMarketEventResult.extraInfo.injuryTime + "'"}
                                                </span>
                                                :
                                                ""
                                            }
                                            <span className='fw-[500] text-sm text-[#FFFFFF] pr-3'>
                                                {item.mainMarketEventResult.liveHomeScore + ' : ' + item.mainMarketEventResult.liveAwayScore}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <div className='flex flex-col w-full xs:gap-3 md:gap-0'>
                                    <div className='xs:flex md:hidden flex-col gap-3 pt-1'>
                                        <div className='flex  items-center justify-center gap-4 w-full'>
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-start'>{item.homeTeam.teamName}</span>
                                            </div>
                                            <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                            <div className='flex items-center gap-2'>
                                                <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                    <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                </div>
                                                <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-start'>{item.awayTeam.teamName}</span>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-center gap-10 border-time-live rounded-xl w-full'>
                                            <div>
                                                <img src={video} alt="" />
                                            </div>
                                            <span className='fw-[500] text-sm text-[#FFFFFF] whitespace-nowrap  text-start'>
                                                {item.mainMarketEventResult.extraInfo.period === 5 ? 'HT' : item.mainMarketEventResult.extraInfo.period === 0 ? 'LIVE' : (item.mainMarketEventResult.extraInfo.period + 'H ' + (item.time % 60 === 59 ? "0" : item.time % 60) + "'")}
                                            </span>
                                            <div className='w-[18px] border border-[#D4B962]'></div>
                                            {item.mainMarketEventResult.extraInfo.injuryTime !== 0 ?
                                                <span className="fw-[500] text-sm text-[#FFFFFF] -ml-1 whitespace-nowrap">
                                                    {" +" + item.mainMarketEventResult.extraInfo.injuryTime + "'"}
                                                </span>
                                                :
                                                ""
                                            }
                                            <span className='fw-[500] text-sm text-[#FFFFFF] pr-3'>
                                                {item.mainMarketEventResult.liveHomeScore + ' : ' + item.mainMarketEventResult.liveAwayScore}
                                            </span>
                                        </div>
                                    </div>

                                    <div className=" soccerGame  flex flex-col w-full justify-between !px-0">
                                        <SoccerTopLiveV3New
                                            period={item.mainMarketEventResult.extraInfo.period}
                                            idGame={item.id}
                                            homeTeam={item.homeTeam.teamName}
                                            awayTeam={item.awayTeam.teamName}
                                            homeScore={item.mainMarketEventResult.liveHomeScore ? Number(item.mainMarketEventResult.liveHomeScore) : 0}
                                            awayScore={item.mainMarketEventResult.liveAwayScore ? Number(item.mainMarketEventResult.liveAwayScore) : 0}
                                            homeScoreTotalCorner={item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveHomeScore ? Number(item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveHomeScore) : 0}
                                            awayScoreTotalCorner={item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveAwayScore ? Number(item.mainMarketEventResult?.subMarketEventResult?.totalCorners?.liveAwayScore) : 0}
                                            tournament={tournament}
                                            item={item}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            blockAllOdds={false}
                                            // blockAllOdds={item.time ? (tournament.includes("e-Football") ? item.time % 60 >= 8 : (item.homeTeam.teamName.includes("(ET)") ? item.time % 60 >= 15 : item.time % 60 >= 45)) : false}
                                            chooseBetSoccer={chooseBetSoccer}
                                            checkUpdateOdds={checkUpdateOdds}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            hiddenFirstHalf={item.mainMarketEventResult.extraInfo.period && (Number(item.mainMarketEventResult.extraInfo.period) === 2 || item.mainMarketEventResult.extraInfo.period === 5) ? true : false}
                                        />
                                    </div >
                                </div>
                            </div>
                            <div className={`transition-opacity duration-200 ease-in-out overflow-hidden pr-2 ${showMoreBet ? ' opacity-100' : ' opacity-0'
                                }`}>
                                {
                                    showMoreBet && (
                                        <div className="gameRatio flex">
                                        </div>
                                    )
                                }
                            </div>


                        </div>

                    </LazyLoad>
                )
            }
            {

                checkNoData(listGameSoccer, 'pb-4')

            }

        </>

    );
};

export default MyMatchGameSoccerLive;