/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { iconToastSuccess, iconToastWarning, iconToastDanger } from './imgExport';

interface ToastProp {
    isOpen: boolean
    message: string
    icon: string
    duration?: number
    closeModal: () => void
}
const ToastDetail: React.FC<ToastProp> = ({
    isOpen,
    message,
    icon,
    duration = 10000,
    closeModal
}) => {
    const [isShow, setIsShow] = useState(isOpen);
    useEffect(() => {
        if (!isShow) {
            showNextToast();
        }
    }, [isOpen])
    // const [countdownTime, setCountdownTime] = useState<number>(duration); // thời gian đếm ngược
    const [widthPercentage, setWidthPercentage] = useState<number>(100);
    var interval: any
    const showNextToast = () => {
        if (isOpen) {
            setWidthPercentage(100)
            setIsShow(true);
            interval = setInterval(() => {
                if (widthPercentage > 0)
                    setWidthPercentage(prevTime => prevTime - 1);
            }, duration / 100);
        }
    }
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setIsShow(false);
                closeModal()
                clearInterval(interval)
                setWidthPercentage(100)
            }, duration)
        }
    }, [isOpen])

    return (
        <>

            <div className={`text-xs text-[#C1C9E1] ${isOpen ? "" : "hidden"} w-full p-4 border border-[#d9d9d9] rounded-lg `}>
                <div className='flex gap-2'>
                    <div className='flex justify-between'>
                        <img src={icon === "Success" || icon.toLowerCase() === "success" ? iconToastSuccess : (icon === "error" ? iconToastDanger : iconToastWarning)} alt=""></img>
                    </div>
                    <div>
                        <p className='text-left text-[18px] uppercase'>notice</p>
                        <p className='text-left text-[14px] mt-1'>{message}</p>
                    </div>
                </div>
                <div className='w-full h-1 bg-[#e5e7ebb3] mt-2 rounded-sm test1' style={{ width: `${widthPercentage}%` }}></div>
            </div>
        </>
    )
}

export default ToastDetail;