/* eslint-disable @typescript-eslint/no-unused-vars */
import { Collapse } from "antd";
import { Collapse_img, Expand, empty } from "../../../components/imgExport"
import type { Dayjs } from 'dayjs';
import type { TimeRangePickerProps } from 'antd';
import dayjs from "dayjs";
import './style.css'
import { HeaderItem } from "../Item/HeaderItem";
import { ItemSoccerSettled } from "../../Mybet/Settled/Item/ItemSoccerSettled";
import { convertTimeZone } from "../../../DateTime";
import { formatPrice } from "../../../until";
import { useEffect, useState } from "react";
const data = [
    { id: 1 }
]
type ItemType = {
    winlose: string | number;
    comission: string | number;
    runningTotal: string | number;
};

type CollapseIconProps = {
    expanded: { isActive?: boolean };
    item: ItemType;
};
interface ItemProp {
    money?: number
    listBill?: any[]
    startDate?: string
    endDate?: string
    setStartDate?: (value: string) => void
    setEndDate?: (value: string) => void
    time?: number
    getCurrentMoney?: () => void
}

export const SoccerStatement: React.FC<ItemProp> = ({
    money,
    listBill,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    time,
    getCurrentMoney
}) => {

    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const [totalWinLose, setTotalWinLose] = useState<number>(0)
    const [commission, setCommission] = useState<number>(0)
    const [runningTotal, setRunningTotal] = useState<number>(0)
    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setStartDate && setStartDate(dateStrings[0])
            setEndDate && setEndDate(dateStrings[1])
        } else {
            console.log('Clear');
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().startOf('week'), dayjs()] },
        { label: 'Last week', value: [dayjs().startOf('week').add(-7, 'd'), dayjs().endOf('week').add(-7, 'd')] },
        { label: 'Past 7 Day', value: [dayjs().add(-6, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().startOf('month'), dayjs()] },
        { label: 'Last month', value: [dayjs().startOf('month').add(-30, 'd'), (dayjs().startOf('month').add(-30, 'd')).endOf('month')] },
    ];

    const formatValue = (value: string | number): JSX.Element => {
        const sign = Number(value) >= 0 ? "" : "-";
        const formattedValue = formatPrice(Math.abs(Number(value)));
        const colorClass = Number(value) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]";

        return (
            <span className={`xs:text-xs sm:text-sm ${colorClass}`}>
                {sign}${formattedValue}
            </span>
        );
    };

    const CollapseContent: React.FC<{ item: ItemType }> = ({ item }) => {
        return (
            <div className="flex items-center justify-between md:w-[420px]">
                <span className={`xs:w-[70px] sm:w-[81px] flex justify-center items-center ${(Number(totalWinLose) >= 0 && '!text-[#33E49A]') || (Number(totalWinLose) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>{formatValue(item.winlose)}</span>
                <span className={`xs:w-[70px] sm:w-[61px] flex justify-center`}>{formatValue(item.comission)}</span>
                <span className={`xs:w-[70px] sm:w-[76px]  flex justify-center items-center ${(Number(runningTotal) >= 0 && '!text-[#33E49A]') || (Number(runningTotal) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>{formatValue(item.runningTotal)}</span>
            </div>
        );
    };

    const CollapseIcon: React.FC<CollapseIconProps> = ({ expanded, item }) => {
        return (
            <div className="w-full flex justify-center xs:gap-0 md:gap-1">
                <CollapseContent item={item} />
                <img src={expanded.isActive ? Collapse_img : Expand} alt="" />
            </div>
        );
    };

    useEffect(() => {
        var sumCommission = 0
        var sumTotalWinLose = 0
        var sumRunningTotal = 0
        if (listBill && listBill.length) {
            for (let index = 0; index < listBill.length; index++) {
                sumCommission = sumCommission + Number(listBill[index].comission)
                sumTotalWinLose = sumTotalWinLose + Number(listBill[index].winlose)
                sumRunningTotal = sumRunningTotal + Number(listBill[index].runningTotal)
            }
        }

        setCommission(Number(sumCommission))
        setTotalWinLose(Number(sumTotalWinLose))
        setRunningTotal(Number(sumRunningTotal))
    }, [listBill])

    return (
        <div className={`  w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530] `}>
            <div className={` xl:h-[calc(100vh-140px)] xs:h-[calc(100vh-250px)]`}>
                {
                    data.length > 0
                        ? (
                            <div className="flex flex-col gap-2 xs:h-[calc(100vh-330px)] xl:h-[calc(100vh-140px)]">
                                <div className="xs:hidden md:flex items-center justify-between ml-6 mr-10 border-dashed border-b border-b-[#D4B962] pb-2">
                                    <div className="flex items-center ">
                                        <span className="xs:text-xs sm:text-sm !text-[#E4E7F1] xs:pr-0 sm:pr-20 font-semibold">Date</span>
                                    </div>
                                    <div className="flex items-center justify-between xs:gap-3 sm:gap-0 w-[420px]">
                                        <span className="xs:text-xs sm:text-sm !text-[#E4E7F1]  font-semibold flex flex-col gap-1 items-center">Total Win/Lose
                                            <span className={`xs:text-xs sm:text-sm ${(Number(totalWinLose) >= 0 && '!text-[#33E49A]') || (Number(totalWinLose) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>{(Number(totalWinLose) < 0 && "-")}${formatPrice(Math.abs(totalWinLose ? totalWinLose : 0))}
                                            </span>
                                        </span>
                                        <span className="text-center xs:text-xs sm:text-sm !text-[#E4E7F1]  font-semibold flex flex-col gap-1 items-center">Commision
                                            <span className={`xs:text-xs sm:text-sm ${(Number(commission) >= 0 && '!text-[#33E49A]') || (Number(commission) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>${formatPrice(Math.abs(commission ? commission : 0))}
                                            </span>
                                        </span>
                                        <span className="xs:text-xs sm:text-sm !text-[#E4E7F1]   font-semibold flex flex-col gap-1 items-center">Running Total
                                            <span className={`xs:text-xs sm:text-sm ${(Number(runningTotal) >= 0 && '!text-[#33E49A]') || (Number(runningTotal) < 0 && ' !text-[#FF4A59]') || (' !text-[#33E49A]')}`}>{(Number(runningTotal) < 0 && "-")}${formatPrice(Math.abs(runningTotal ? runningTotal : 0).toFixed(2))}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col h-full overflow-y-scroll">
                                    {(listBill && listBill.length > 0 && listBill.map((item: any, index: number) => (
                                        <Collapse
                                            className='w-colappse collapse-league-V2 '
                                            expandIconPosition='end'
                                            expandIcon={(expanded) => <CollapseIcon expanded={expanded} item={item} />
                                            }
                                            items={[{
                                                label: <div className="flex flex-col gap-[10px]">
                                                    <div className='text-[#D4B962] fw-[500] text-base flex items-center uppercase xs:text-xs sm:text-sm'>
                                                        <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                        </div>
                                                        {convertTimeZone(item.endDate.toString(), "America/Chicago", timeZone).slice(0, 10)}
                                                    </div>

                                                    {/* <div className="xs:flex md:hidden flex-col gap-1">
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Total Win/Lose </span>
                                                            <span className={`text-sm pl-2 ${Number(item.winlose) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.winlose) >= 0 ? `$${formatPrice(Number(item.winlose))}` : `$${formatPrice(Math.abs(Number(item.winlose)))}`}</span>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Commision </span>
                                                            <span className={`text-sm pl-2 ${Number(item.comission) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.comission) >= 0 ? `$${formatPrice(Number(item.comission))}` : `$${formatPrice(Math.abs(Number(item.comission)))}`}</span>
                                                        </div>
                                                        <div className="flex items-center justify-between">
                                                            <span className="text-sm !text-[#E4E7F1] uppercase">Running Total </span>
                                                            <span className={`text-sm pl-2 ${Number(item.runningTotal) >= 0 ? "!text-[#33E49A]" : "!text-[#FF4A59]"}`}>{Number(item.runningTotal) >= 0 ? `$${formatPrice(Number(item.runningTotal))}` : `$${formatPrice(Math.abs(Number(item.runningTotal)))}`}</span>
                                                        </div>
                                                    </div> */}
                                                </div>,

                                                children:
                                                    <div className="flex flex-col">
                                                        <HeaderItem />
                                                        <div className={`flex flex-col xs:gap-[18px] xl:gap-0 w-full overflow-y-scroll ${item.listBill.length >= 5 ? "h-fit" : "h-full"}`}>
                                                            {/* <div className={`flex flex-col xs:gap-[18px] xl:gap-0 w-full overflow-y-scroll ${item.listBill.length >= 5 ? "h-[calc(100vh-320px)]" : "h-full"}`}> */}
                                                            {item.listBill && item.listBill.length && item.listBill.map((item: any) => (
                                                                <ItemSoccerSettled key={item.id} status={1} item={item} />
                                                            ))}
                                                        </div>
                                                    </div>
                                            }]}
                                        />
                                    )))}

                                </div>

                            </div>
                        )
                        : (
                            <div className="flex flex-col">
                                <div className="pt-[120px] flex flex-col items-center gap-3">
                                    <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                    <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                                </div>
                            </div>
                        )
                }
            </div>

        </div>
    )
}