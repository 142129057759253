/* eslint-disable array-callback-return */

import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3, getChangeColorText } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import { bg_checked, checked } from '../../../../../components/imgExport';
interface SoccerTopHDPFProps {
    live?: boolean
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    item: InfoGameDetailSoccer[]
    oddsOld?: Odd[]
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number, color?: string
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTop1x2F_V2New: React.FC<SoccerTopHDPFProps> = ({
    live, homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip,
    updateOdds
}) => {
    const odd = useRef<any[]>([item])
    const oddOld = useRef<any[]>([oddsOld])
    const updateArrayOdds = async () => {
        item.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             // setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             // setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    const [itemState, setItemState] = useState<any[]>([item]);
    useEffect(() => {
        setItemState(item)
        odd.current = item
        oddOld.current = oddsOld ? oddsOld : item
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    // useEffect(() => {
    //     setTimeout(() => {
    //         oddOld.current = odd.current
    //     }, 3000);
    // }, [itemState])

    return (
        <>
            <div className={`${live ? 'w-1/3' : 'w-1/4'}  flex flex-col justify-start a1x2`}>
                <div className='flex justify-between w-full h-[110px] items-center '>
                    <div className='w-full flex flex-col hdp gap-1'>
                        {itemState[0]?.prices?.map((itemPrices: any, indexPrices: number) => {
                            if (itemPrices && itemPrices.option && itemPrices.option === '1')
                                return <div className={`justify-center w-full flex items-center`} key={indexPrices}
                                >
                                    {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                        ?
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] overflow-hidden ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price,
                                            () => (listChooseBet && odd.current?.[0]?.prices?.[0]?.price !== oddOld.current?.[0]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemPrices.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, "Home " + itemPrices.price.toString(), itemPrices.option, homeScore, awayScore, getChangeColorText(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price)) : ""),
                                            dateFilterSoccer,
                                            listDateFilterSoccer
                                        )} ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime 1x2")}`}
                                            onClick={() => chooseBetTop?.(
                                                0, itemState[0].id, homeTeam, "Home " + itemPrices.price.toString(), itemPrices.option
                                            )}
                                        >
                                            <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                                {itemPrices.option}
                                            </span>
                                            <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                                <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemPrices.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[0]?.price, oddOld.current?.[0]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                            {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam, "Fulltime 1x2").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}
                                        </div>
                                        :
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                    }
                                </div>
                        })}
                        {itemState[0]?.prices?.map((itemPrices: any, indexPrices: number) => {
                            if (itemPrices && itemPrices.option && itemPrices.option === '2')
                                return <div className={`justify-center w-full flex items-center`} key={indexPrices}
                                >
                                    {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                        ?
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price,
                                            () => (listChooseBet && odd.current?.[0]?.prices?.[1]?.price !== oddOld.current?.[0]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemPrices.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, "Away " + itemPrices.price.toString(), itemPrices.option, homeScore, awayScore, getChangeColorText(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price)) : ""),
                                            dateFilterSoccer,
                                            listDateFilterSoccer
                                        )} ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime 1x2")}`}
                                            onClick={() => chooseBetTop?.(
                                                0, itemState[0].id, awayTeam, "Away " + itemPrices.price.toString(), itemPrices.option
                                            )}
                                        >
                                            <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                                {itemPrices.option}
                                            </span>
                                            <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                                <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemPrices.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[1]?.price, oddOld.current?.[0]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                            {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, awayTeam, "Fulltime 1x2").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                        </div>
                                        :
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                    }
                                </div>
                        })}
                        {itemState[0]?.prices?.map((itemPrices: any, indexPrices: number) => {
                            if (itemPrices && itemPrices.option && itemPrices.option === 'X')
                                return <div className={`justify-center w-full flex items-center`} key={indexPrices}
                                >
                                    {itemState && itemState.length > 0 && itemState[0].prices && itemState[0].prices.length > 0
                                        ?
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-end w-full rounded-md relative hover:!border-[#D4B962] ${getChangeColorSoccer_V3(odd.current?.[0]?.prices?.[2]?.price, oddOld.current?.[0]?.prices?.[2]?.price,
                                            () => (listChooseBet && odd.current?.[0]?.prices?.[2]?.price !== oddOld.current?.[0]?.prices?.[2]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[0].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[0].id)].option === itemPrices.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[0].id, "Draw " + itemPrices.price.toString(), itemPrices.option, homeScore, awayScore, getChangeColorText(odd.current?.[0]?.prices?.[2]?.price, oddOld.current?.[0]?.prices?.[2]?.price)) : ""),
                                            dateFilterSoccer,
                                            listDateFilterSoccer
                                        )} ${checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Fulltime 1x2")}`}
                                            onClick={() => chooseBetTop?.(
                                                0, itemState[0].id, homeTeam + " / " + awayTeam, "Draw " + itemPrices.price.toString(), itemPrices.option
                                            )}
                                        >
                                            <span className="xs:text-xs lg:text-sm font-[400] text-[#E4E7F1] w-[50%] h-full flex items-center justify-center">
                                                {itemPrices.option}
                                            </span>
                                            <div className={`relative py-1 w-[50%] cursor-pointer flex justify-center`}>
                                                <p><b className='text-sm !font-[400] text-[#D4B962]'>{Number(itemPrices.price).toFixed(2)}</b></p>
                                                <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[0]?.prices?.[2]?.price, oddOld.current?.[0]?.prices?.[2]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                                            </div>
                                            {checkSelectSoccerNew_V2(0, 0, itemState[0]?.id ?? 0, listChooseBet, homeTeam + " / " + awayTeam, "Fulltime 1x2").includes("icon-selected") ? (<div><img src={bg_checked} alt="" className='absolute top-0 left-0 rotate-[270deg]' /><img src={checked} alt="" className='absolute top-[6px] left-1' /></div>) : ""}

                                        </div>
                                        :
                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                            </div>
                                        </div>
                                    }
                                </div>
                        })}
                        {!itemState[0]?.prices && (
                            <>
                                <div className={`justify-center w-full flex items-center`}>
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`justify-center w-full flex items-center`}>
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`justify-center w-full flex items-center`}>
                                    <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#202531] flex justify-center w-full rounded-md relative`}>
                                        <div className={`relative py-1 w-full flex justify-center`}>
                                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SoccerTop1x2F_V2New;