/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Collapse, Affix, Progress } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import '../page/homepage/HomePage.css'
import '../page/DetailPage/styles.css'
import Banner from '../page/homepage/components/Content/Banner';
import { Collapse_img, ElipseRed, Expand, Trash, filter_icon, reload, soccer_2, start } from '../components/imgExport';
import { useDispatch, useSelector } from 'react-redux';
import { items } from '../services/data';
import useLoading from '../hook/useLoading';
import useToast from '../hook/useToast';
import * as bettingApi from '../api/betting/bettingApi';
import * as homePageApi from '../api/homePage/homePageApi';
import * as homePage_V2Api from '../api/homePage/homePage_V2Api';
import * as loginApi from '../api/login/loginApi';
import { selectorUser, setUserMoney } from '../store/slices/userSlice';
import { itemMenu } from '../page/homepage/components/MainNavi/Tabs/TabsNaviV2';
import useThrottle from '../hook/useThrottle';
import { getPiceWin, getPiceWinStright } from '../until';
import ModalWarning from '../components/Modal/ModalWarning';
import { useLocation } from 'react-router-dom';
import useDebounce from '../hook/useDebounce';
import TotalStakeComponentV2 from '../page/homepage/components/TotalStake/TotalStakeV2';
import BetTabsSoccer from '../page/homepage/components/Content/Sidebar/BetTabSoccer';
import io from 'socket.io-client';
import { API_URL_SOCCER } from '../env';
import LazyLoad from 'react-lazyload';
import './style.css'
import EmtyBetSlip from '../components/EmtyBetSlip';
import DayTime_V2 from '../page/homepage/components/Content/GameList/dayTime_V2';
import { Layout } from './Layout';
import ModalBetSlip from '../page/homepage/components/Content/Sidebar/ModalBetSlip';
import HeadNavi_V2 from '../page/homepage/components/MainNavi/Head/HeadNavi_V2';
import GameSoccer_V2New from '../page/homepage/components/Content/GameList/GameSoccer_V2New';
import ToastGlobal from '../components/ToastGlobal';
import MyMatchGameSoccerLive from '../page/homepage/components/Content/GameList/MyMatchGameSoccerLive';
import { ModalTourName } from '../components/Modal/ModalTourname';
import { DrawerTourNameMobile } from '../components/Drawer/DrawerTourNameMobile';
import { FooterMobile } from '../components/FooterMobile';
import { getPresentMoney, } from '../api/billAcceptor/billAcceptorApi';
import { useNavigate } from 'react-router-dom';
import * as myBetApi from '../api/myBet/myBetApi';
import DayTimeMyMatch from '../page/homepage/components/Content/GameList/dateTimeMyMatch';
import MyMatchGameSoccer from '../page/homepage/components/Content/GameList/MyMatchGameSoccer';

var intervalPrematchTournament: any
var intervalPrematch: any
var intervalListDate: any
var intervalTotalGamePrematch: any
var intervalPercent: any


export interface InfoChooseGame {
    icon: string, lable: string
}

function formatDateNow() {
    let d = new Date().toLocaleDateString()
    return d
}

// function formatTimeNow() {
//   let d = new Date().toLocaleTimeString()
//   return d
// }

function formatDate(UTC: string) {
    let d = new Date(UTC).toLocaleDateString()
    return d
}

// function formatTime(UTC: string) {
//   let d = new Date(UTC).toLocaleTimeString()
//   return d
// }

function arraysEqual<T>(array1: T[], array2: T[]): boolean {
    // Nếu độ dài của mảng không bằng nhau thì chắc chắn chúng không bằng nhau
    if (array1.length !== array2.length) {
        return false;
    }

    // So sánh từng phần tử của hai mảng
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    // Nếu tất cả các phần tử đều bằng nhau, trả về true
    return true;
}

interface DashBoardProps {
    showSpan: boolean;
    setShowSpan: React.Dispatch<React.SetStateAction<boolean>>;
    showNLF: boolean;
    setShowNLF: React.Dispatch<React.SetStateAction<boolean>>;
    showMLB: boolean;
    setShowMLB: React.Dispatch<React.SetStateAction<boolean>>;
    isHomePage: boolean;
    setIsHomePage: React.Dispatch<React.SetStateAction<boolean>>;
}
const MyMatch: React.FC<DashBoardProps> = ({ showSpan, setShowSpan, showNLF, setShowNLF, showMLB, setShowMLB, isHomePage, setIsHomePage }) => {
    const navigator = useNavigate()
    const dispatch = useDispatch();
    const pushToast = useToast();
    const pushLoading = useLoading();
    const { userId, presentMoney } = useSelector(selectorUser);
    const location = useLocation();
    const [socket, setSocket] = useState<any>(null);
    const [menu, setMenu] = useState<any>(items)
    const [ipDevice, setIpDevice] = useState<any>("");
    const [locationDevice, setLocationDevice] = useState<any>("");
    const [time, setTime] = useState(0)

    const scrollTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    const viewSettings = useThrottle(async () => {
        const ketqua = await homePageApi.viewSettings()
        setMenu([])
        if (ketqua?.success) {
            if (ketqua.data.bettings.Straight === 1) {
                setMenu((data: any) => [...data, {
                    key: '1',
                    label: 'Straight',
                }])
            }
            if (keyGameTabSoccer === "gameOffline") {
                if (ketqua.data.bettings.Parlay === 1) {
                    setMenu((data: any) => [...data, {
                        key: '2',
                        label: 'Parlay',
                    }])
                }
            }
        }
    }, 500)


    const resetData = () => {
        setTotalBetSoccer(false)
        setShowTotalStakeSoccer(false)
        setShowModalWarningSoccer(false)
        setIsWarningBetParlaySoccer(false)
        setTextWarningBetParlaySoccer("")
        setTotalStakeSoccer(0)
        setTotalAmountStrightSoccer(0)
        setTotalAmountParlaySoccer(0)

        setTournamentsSoccer(itemMenu[4])

        setListChooseGameSoccer([])
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        setListBetStrightSoccer([])
        setListBetParlaySoccer([])
        setListBillStrightSoccer([])
        setListBillParlaySoccer(undefined)

        setListTournamentSoccerNew([])
        setListTournamentSoccerNewLive([]);
        setListDateFilterSoccer([]);
    }

    const [showTotalBetSoccer, setTotalBetSoccer] = useState(false)
    const [showTotalStakeSoccer, setShowTotalStakeSoccer] = useState(false)
    const [showTotalStakeSoccer_V2, setShowTotalStakeSoccer_V2] = useState(false)
    const [showModalWarningSoccer, setShowModalWarningSoccer] = useState(false)
    const [updateOdds, setUpdateOdds] = useState(false)
    const [keyBetTabsSoccer, setKeyBetTabsSoccer] = useState("1");
    const [keyGameTabSoccer, setKeyGameTabSoccer] = useState<"gameOnline" | "gameOffline">('gameOffline');
    const [typeListGameSoccer, setTypeListGameSoccer] = useState<"main" | "select">('main');
    const [selectTournament, setSelectTournament] = useState<string[]>([]);

    const [isWarningBetParlaySoccer, setIsWarningBetParlaySoccer] = useState(false);
    const [textWarningBetParlaySoccer, setTextWarningBetParlaySoccer] = useState("");
    const [totalStakeSoccer, setTotalStakeSoccer] = useState<number>(0);
    const [totalAmountStrightSoccer, setTotalAmountStrightSoccer] = useState(0)
    const [totalAmountParlaySoccer, setTotalAmountParlaySoccer] = useState(0)
    const [totalOddsParlaySoccer, setTotalOddsParlaySoccer] = useState(0)
    const [totalTParlaySoccer, setTotalTParlaySoccer] = useState(1)
    const [totalGameSoccer, setTotalGameSoccer] = useState(0)
    const [totalGameLiveSoccer, setTotalGameliveSoccer] = useState(0)
    const [totalAllGameSoccer, setTotalAllGameSoccer] = useState(0)
    const [showModalTourName, setShowModalTourName] = useState(false)
    const [showDrawerTourNameMobile, setShowDrawerTourNameMobile] = useState(false)
    const [tournamentsSoccer, setTournamentsSoccer] = useState(itemMenu[4])

    // const [listChooseTournament, setListChooseTournament] = useState<number[]>([])

    const [listChooseGameSoccer, setListChooseGameSoccer] = useState<number[]>([])
    const [listChooseBetSoccer, setListChooseBetSoccer] = useState<bettingApi.ChooseBetSoccer[]>([])
    const [listAddStrightBetsSlipSoccer, setListaddStrightBetsSlipSoccer] = useState<bettingApi.InfoAddStrightSoccerTmp[]>([])
    const [listParlayBetsSlipSoccer, setListParlayBetsSlipSoccer] = useState<bettingApi.InfoParlaySoccerTmp[]>([])

    const [listBetStrightSoccer, setListBetStrightSoccer] = useState<bettingApi.InfoBetStrightSoccer[]>([])
    const [listBetParlaySoccer, setListBetParlaySoccer] = useState<bettingApi.InfoParlaySoccer[]>([])

    const [listBillStrightSoccer, setListBillStrightSoccer] = useState<bettingApi.InfoBillStrightSoccer[]>([])
    const [listBillParlaySoccer, setListBillParlaySoccer] = useState<bettingApi.InfoBillParlaySoccer>()
    const [listTournamentSoccerNew, setListTournamentSoccerNew] = useState<homePageApi.InfoTournamentSoccer[]>([])
    const [listTournamentSoccerNewLive, setListTournamentSoccerNewLive] = useState<homePageApi.InfoTournamentSoccer[]>([])
    const [listTournamentSoccerNewSideBar, setListTournamentSoccerNewSideBar] = useState<homePageApi.InfoTournamentSoccer[]>([])

    const [listDateFilterSoccer, setListDateFilterSoccer] = useState<homePage_V2Api.infoListDate[]>([])
    const [dateFilterSoccer, setDateFilterSoccer] = useState<string>('All')
    const [resetBetGame, setResetBetGame] = useState<boolean>(false)
    const [settingBet, setSettingBet] = useState<bettingApi.InfoSettingsBet>()
    const [minBetStraight, setMinBetStraight] = useState<number>(10)
    const [maxBetStraight, setMaxBetStraight] = useState<number>(10000)
    const [minBetParlay, setMinBetParlay] = useState<number>(10)
    const [maxBetParlay, setMaxBetParlay] = useState<number>(10000)




    const handleCheckQuantityMoneyStraight = async (keytab: number, total: number) => {
        pushLoading(true);
        var ketQua
        if (keytab === 1) {
            ketQua = await homePageApi.checkQuantityMoneyStraight(total);
        } else if (keytab === 2) {
            ketQua = await homePageApi.checkQuantityMoneyParlay(total);
        } else {
            ketQua = await homePageApi.checkQuantityMoneyTeaser(total);
        }
        if (ketQua?.success) {
            // aStoreStrightBetsSlip()
            pushLoading(false);
            return true
        } else {
            pushToast(ketQua?.message, "warn");
            pushLoading(false);
            return false
        }
    }

    const showHideTotalStakeSoccer = () => {
        setShowTotalStakeSoccer(!showTotalStakeSoccer)
    }
    const showHideTotalStakeSoccer_V2 = () => {
        setShowTotalStakeSoccer_V2(!showTotalStakeSoccer_V2)
    }
    //Soccer
    const resetTotalGameSoccer = async () => {
        totalGamePrematchSoccer.current = 0
        totalGameLivePrematchSoccer.current = 0
        setTotalGameSoccer(0)
        setTotalGameliveSoccer(0)
    }

    const checkFirstTimeDateFilter = useRef(0)
    const changeDateFilter = async (date: string) => {
        await setDateFilterSoccer(date)
        await handleRadioChangeSoccer('gameOffline')
        if (date === "All") {
            gAllTournamentSoccer("allgame", undefined, selectTournament)
        }
        // setListChooseBetSoccer([])
        // setListaddStrightBetsSlipSoccer([])
        // setListParlayBetsSlipSoccer([])
    }

    const resetBet = useThrottle(async () => {
        getMyBetRunning()
        var timeTotal = 10
        const interval = setInterval(() => {
            if (timeTotal > 0) {
                timeTotal -= 1
                setTime(timeTotal)
            } else {
                clearInterval(interval)
            }
        }, 1000);

        pushLoading(true)
        // clearInterval(interval);
        setResetBetGame(true)
        totalGamePrematchSoccer.current = 0
        setTotalGameSoccer(0)
        // interval = setInterval(() => {
        //     gAllGameSoccerOffline()
        // }, 60000);
        pushLoading(false)
    }, 10000)

    const updateBetGameReset = async () => {
        setResetBetGame(false)
    }



    const gAllTournamentSoccer = useThrottle(async (value?: string, reload?: boolean, selectTournamentParam?: string[], listTourrnamentMyMatchArray?: string[]) => {
        if (window.location.pathname === "/my-match") {
            if (!reload) {
                clearInterval(intervalPrematchTournament)
            }
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const ketQua = await homePage_V2Api.allTournamentSoccerSbobet("All", timeZone, JSON.stringify(listChooseGameSoccer));
            if (ketQua?.success) {
                if (selectTournamentParam && selectTournamentParam.length !== 0) {
                    setTotalAllGameSoccer(
                        ketQua.data.filter(
                            item => selectTournamentParam.includes(item.tournament)).length
                            ?
                            Number(ketQua.data.filter(item => selectTournamentParam.includes(item.tournament)).reduce((acc, itemtournament: any) => acc + itemtournament?.totalMatch, 0))
                            :
                            0
                    )
                    setListTournamentSoccerNewSideBar(ketQua.data)
                    if (value) {
                        setListTournamentSoccerNew(ketQua.data.filter(item => selectTournamentParam.includes(item.tournament) && (listTournamentMyMatch.includes(item.tournament) || (listTourrnamentMyMatchArray && listTourrnamentMyMatchArray.includes(item.tournament)))))
                    } else {
                        setListTournamentSoccerNew(ketQua.data.filter(item => selectTournamentParam.includes(item.tournament) && (listTournamentMyMatch.includes(item.tournament) || (listTourrnamentMyMatchArray && listTourrnamentMyMatchArray.includes(item.tournament)))))
                    }
                } else {
                    setTotalAllGameSoccer(ketQua?.totalMatch ? ketQua?.totalMatch : 0)
                    setListTournamentSoccerNewSideBar(ketQua.data)
                    if (value) {
                        setListTournamentSoccerNew(ketQua.data.filter(item => listTournamentMyMatch.includes(item.tournament) || (listTourrnamentMyMatchArray && listTourrnamentMyMatchArray.includes(item.tournament))))
                    }
                    // if (value) {
                    //   if (selectTournament && selectTournament.length !== 0) {
                    //     setListTournamentSoccerNew(ketQua.data.filter(item => selectTournament.includes(item.tournament)))
                    //   } else {
                    //     setListTournamentSoccerNew(ketQua.data)
                    //   }
                    // }
                }
                clearInterval(intervalTotalGamePrematch)
                intervalTotalGamePrematch = setInterval(() => {
                    gAllTournamentSoccer(undefined, true, selectTournamentParam)
                }, 60000)
            } else {
                setListTournamentSoccerNewSideBar([]);
                pushToast(ketQua?.message, "warn");
            }
        }
        // intervalPrematchTournament = setInterval(() => {
        //     clearAllStrightBetsSlipSoccer()
        //     // gAllTournamentSoccer()
        // }, 3600000)
    }, 500)

    const gAllTournamentSoccerLive = useThrottle(async () => {
        pushLoading(true);
        const ketQua = await homePage_V2Api.liveTournamentSoccerSbobet();
        if (ketQua?.success) {
            setListTournamentSoccerNewLive(ketQua.data.filter((item: any) => (listTournamentLiveMyMatch.includes(item.tournament))))
        } else {
            setListTournamentSoccerNewLive([]);
            pushToast(ketQua?.message, "warn");
        }
        pushLoading(false);
    }, 500)

    const cMoneyBettingSoccer = useThrottle(async (listBet?: bettingApi.InfoBetStrightSoccer[] | bettingApi.InfoParlaySoccer[]) => {
        pushLoading(true);
        var checkQuantityMoneyStraight
        if (keyBetTabsSoccer === "1") {
            checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabsSoccer, totalAmountStrightSoccer)
        }
        if (keyBetTabsSoccer === "2") {
            checkQuantityMoneyStraight = await handleCheckQuantityMoneyStraight(+keyBetTabsSoccer, totalAmountParlaySoccer)
        }
        if (!checkQuantityMoneyStraight) {
            pushLoading(false);
            return
        }

        if (keyBetTabsSoccer === "1") {
            const ketQua = await bettingApi.checkMoneyBetting(presentMoney, totalAmountStrightSoccer, listBet ? listBet : listBetStrightSoccer, keyBetTabsSoccer === "1" ? "Straight" : "Parlay");
            if (ketQua?.success) {
                aStoreStrightBetsSlipSoccer(listBet ? listBet : listBetStrightSoccer)
            } else {
                pushToast(ketQua?.message, "warn");
                if (ketQua.type === "nem") {
                    setShowModalWarningSoccer(true)
                }
            }
        }
        if (keyBetTabsSoccer === "2") {
            await handleDoubleCheckBetParlaySoccer(listBet ? listBet : listBetParlaySoccer)
        }

        pushLoading(false);
    }, 1000)


    const [percent, setPercent] = useState(0)
    const aStoreStrightBetsSlipSoccer = useThrottle(async (listBet?: bettingApi.InfoBetStrightSoccer[]) => {
        clearInterval(intervalPercent)
        setPercent(0)
        pushLoading(true)
        if (listBetStrightSoccer.length) {
            if (listBetStrightSoccer[0]?.live && listBetStrightSoccer[0]?.live === true) {
                listBetStrightSoccer[0].stage = "1"
            } else {
                listBetStrightSoccer[0].stage = "0"
            }
            listBetStrightSoccer[0].win = Math.round(Number(listBetStrightSoccer[0].win))
        }

        if (listBet && listBet.length) {
            if (listBet[0]?.live && listBet[0]?.live === true) {
                listBet[0].stage = "1"
            } else {
                listBet[0].stage = "0"
            }
            listBet[0].win = Math.round(Number(listBet[0].win))
        }
        const ketQua = await bettingApi.addStoreStraightSoccerBetsSlip(userId, listBet ? listBet : listBetStrightSoccer, ipDevice, locationDevice);
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.presentMoney || "0"))
            if (listBetStrightSoccer[0]?.live === true || (listBet && listBet[0]?.live === true)) {
                ketQua.data[0].live = true
                setListBillStrightSoccer(ketQua.data)
            } else {
                ketQua.data[0].stage = "0"
                setListBillStrightSoccer(ketQua.data)
            }
            clearAllStrightBetsSlipSoccer()
            pushToast("Successfully Betting", "success")
            setOpenDrawerBestSlip(false)
        } else {
            if (keyGameTabSoccer === 'gameOnline') {
                pushToast(ketQua?.message, "warn");
            } else {
                pushToast(ketQua?.message, "warn");
            }
        }
        pushLoading(false);
    }, 1000)

    const aStoreParlayBetsSlipSoccer = useThrottle(async (listBet?: bettingApi.InfoParlaySoccer[]) => {
        pushLoading(true);
        listBetParlaySoccer.forEach((element, index) => {
            listBetParlaySoccer[index].stage = "0"
        });
        const ketQua = await bettingApi.addStoreParlaySoccer(userId, totalOddsParlaySoccer, totalAmountParlaySoccer, listBet ? listBet : listBetParlaySoccer, ipDevice, locationDevice);
        if (ketQua?.success) {
            dispatch(setUserMoney(ketQua.presentMoney || "0"))
            setListBillParlaySoccer(ketQua.data)
            // setBillCode(ketQua.billCode)
            clearAllStrightBetsSlipSoccer()
            pushToast("Successfully Betting", "success")
            setOpenDrawerBestSlip(false)
        } else {
            pushToast(ketQua?.message, "warn");
        }
        pushLoading(false);
    }, 1000)

    const handelChooseTournamentsSoccer = (value: itemMenu) => {
        if (value.id !== 9) {
            setTournamentsSoccer(value)
            // gListGameByTournamentSoccer(value.id)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepageSoccer()
        }
        window.location.href = value.href
        // navigator(value.href)
    }

    const checkAddStrightBetsSlipSoccer = useThrottle(async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeLiveScore?: number, awayLiveScore?: number
    ) => {
        pushLoading(true)
        setUpdateOdds(false)
        if (keyGameTabSoccer === "gameOnline") {
            await setListChooseBetSoccer([])
            await setListaddStrightBetsSlipSoccer([])
        }

        const isDuplicate = listChooseBetSoccer.some(
            (bet) => bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order
        );
        var updatedList: any

        if (keyGameTabSoccer === "gameOnline") {
            updatedList = isDuplicate
                ? listChooseBetSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order))
                : [{ country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame }];
        } else {
            updatedList = isDuplicate
                ? listChooseBetSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting && bet?.order === order))
                : [...listChooseBetSoccer, { country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame }];
        }

        var checkMax = Math.max(...[Number(settingBet?.quantity.straight) ? Number(settingBet?.quantity.straight) : 0, Number(settingBet?.quantity.parlay) ? Number(settingBet?.quantity.parlay) : 0, Number(settingBet?.quantity.teaser) ? Number(settingBet?.quantity.teaser) : 0, 1])
        if (updatedList.length > checkMax) {
            pushToast(`Your bet amount exceeds the limit ${checkMax}`, "warn");
        } else {
            setListChooseBetSoccer(updatedList);
        }
        if (!isDuplicate) {
            aStrightBetsSlipSoccer(country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live, idGame, homeLiveScore, awayLiveScore)
        }
        else
            rStrightBetsSlipSoccer(country, order, id, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, option, live)


        setTimeout(() => {
            var inputStake = document.getElementById("input-stake");
            var inputStakeMobile = document.getElementById("input-stake-mobile");
            inputStake && inputStake.focus()
            inputStakeMobile && inputStakeMobile.focus()
        }, 500)

        setTimeout(() => {
            pushLoading(false)
        }, 2000)
    }, 400)

    const settingsBet = useDebounce(async () => {
        const ketQua1 = await bettingApi.getSettingsBet()
        if (ketQua1?.success) {
            setSettingBet(ketQua1.data)
        }
    }, 500)

    const aStrightBetsSlipSoccer = async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeLiveScore?: number, awayLiveScore?: number
    ) => {
        pushLoading(true);
        if (settingBet?.quantity.straight && settingBet?.quantity.straight > listAddStrightBetsSlipSoccer.length) {
            const ketQua = await bettingApi.addStraightBetSoccerBetsSlip(
                country, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent
            )
            if (ketQua?.success) {
                if (keyGameTabSoccer === "gameOnline") {
                    setListaddStrightBetsSlipSoccer((prevList) => [
                        { ...ketQua.data, isCheck: true, amount: 0, win: 0, id, option, live, idGame, homeLiveScore, awayLiveScore }
                    ]);
                } else {
                    setListaddStrightBetsSlipSoccer((prevList) => [
                        ...prevList,
                        { ...ketQua.data, isCheck: true, amount: 0, win: 0, id, option, live, idGame, homeLiveScore, awayLiveScore }
                    ]);
                }

                setTotalStakeSoccer(0)
            } else {
                pushToast(ketQua?.message, "warn");
            }
        } else {
            if (keyBetTabsSoccer === "1") {
                pushToast(`Your straight bet amount exceeds the limit ${settingBet?.quantity?.straight ? settingBet?.quantity?.straight.toString() : ""}`, "warn");
            }
        }
        pushLoading(false);
    }

    const rStrightBetsSlipSoccer = async (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: bettingApi.lineBettingSoccer, dateEvent: string, option?: any, live?: boolean
    ) => {
        const updatedList = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
        var check = 0
        await updatedList && updatedList.map(item => {
            if (item.change) {
                check++
            }
        })
        if (check === 0) {
            setHiddenBetPlaceSoccer(false)
        } else {
            setHiddenBetPlaceSoccer(true)
        }
        setListaddStrightBetsSlipSoccer(updatedList)
        rParlayBetsSlipSoccer(game, teamChoose, lineBetting)
    }


    const rParlayBetsSlipSoccer = async (game: string, teamChoose?: string, lineBetting?: bettingApi.lineBettingSoccer) => {
        var updatedList
        if (teamChoose && lineBetting) {
            updatedList = listParlayBetsSlipSoccer.filter((bet) => !(bet.teamChoose === teamChoose && bet.lineBetting === lineBetting))
        } else {
            updatedList = listParlayBetsSlipSoccer.filter((bet) => (bet.game === game))
        }
        var check = 0
        await updatedList && updatedList.map(item => {
            if (item.change) {
                check++
            }
        })
        if (check === 0) {
            setHiddenBetPlaceSoccer(false)
        } else {
            setHiddenBetPlaceSoccer(true)
        }
        if (settingBet?.quantity.parlay && settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length) {
            const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => (bet.game !== game || bet.teamChoose !== teamChoose || bet.lineBetting !== lineBetting))
            await setListParlayBetsSlipSoccer(updatedListParlay)
            if (updatedListParlay.length) {
                aParlayBetsSlipSoccer()
            }
        } else {
            const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => (bet.game !== game || bet.teamChoose !== teamChoose || bet.lineBetting !== lineBetting))
            setListParlayBetsSlipSoccer(updatedListParlay)
        }
    }

    const checkConditionsBetParlaySoccer = useDebounce((arr: bettingApi.InfoParlayBetsSlipTMP[]): Boolean => {
        if (arr.length < 2) {
            setIsWarningBetParlaySoccer(true)
            setTextWarningBetParlaySoccer("3+ selections needed for a Parlay.")
            return false; // Trường hợp 1
        }
        // Kiểm tra xem có bất kỳ cặp game nào giống nhau hay không
        const gameSet: Set<string> = new Set(); // Sử dụng Set để kiểm tra các game duy nhất
        for (const item of arr) {
            if (gameSet.has(item.game)) {
                checkWarningParlaySoccer(item.game, arr)
                setIsWarningBetParlaySoccer(true)
                setTextWarningBetParlaySoccer("Parlay cannot be placed as some of your legs cannot be combined.")
                return false; // Trường hợp 1
            } else {
                gameSet.add(item.game);
            }
        }
        checkWarningParlaySoccer("", arr)
        // setIsWarningBetParlaySoccer(false)
        // Nếu không có cặp game nào giống nhau và mảng có 2 hoặc nhiều hơn 2 phần tử
        return true; // Trường hợp 2
    }, 1000)

    const checkWarningParlaySoccer = useThrottle((game: string, arr: bettingApi.InfoParlaySoccerTmp[]) => {
        var check = 0
        if (game) {
            listParlayBetsSlipSoccer.map((item) => {
                if (item.game === game) {
                    check = 1
                    item.isWarning = true
                    setIsWarningBetParlaySoccer(true)
                    setTextWarningBetParlaySoccer("Parlay cannot be placed as some of your legs cannot be combined.")
                }
                if ((item.lineBetting === "Fulltime 1x2" || item.lineBetting === "First Half 1x2") && item.scoreChoose.split(" ")[1] && Number(item.scoreChoose.split(" ")[1]) <= 1.3) {
                    check = 1
                    item.isWarning = true
                    setIsWarningBetParlaySoccer(true)
                    setTextWarningBetParlaySoccer("Parlay cannot be placed as some of your legs odd cannot as required.")
                }
            })
        } else {
            listParlayBetsSlipSoccer.map((item) => {
                if ((item.lineBetting === "Fulltime 1x2" || item.lineBetting === "First Half 1x2") && item.scoreChoose.split(" ")[1] && Number(item.scoreChoose.split(" ")[1]) <= 1.3) {
                    check = 1
                    item.isWarning = true
                    setIsWarningBetParlaySoccer(true)
                    setTextWarningBetParlaySoccer("Parlay cannot be placed as some of your legs odd cannot as required.")
                }
            })
            // const updatedList = listParlayBetsSlipSoccer.map(item => ({ ...item, isWarning: false }));
            // setListParlayBetsSlipSoccer(updatedList);
        }
        if (check === 0) {
            setIsWarningBetParlaySoccer(false)
            setTextWarningBetParlaySoccer("")
        }

    }, 500)

    const aParlayBetsSlipSoccer = useThrottle(async (
    ) => {
        pushLoading(true);
        if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length || (settingBet?.quantity.parlay === listParlayBetsSlipSoccer.length && settingBet?.quantity.parlay - 1 === listChooseBetSoccer.length))) {
            const ketQua = await bettingApi.addParlayBetSoccer(listChooseBetSoccer)
            if (ketQua?.success) {
                setListParlayBetsSlipSoccer(ketQua.data.data.map(bet => ({ ...bet, isCheck: true, isWarning: false })));
                // setTotalAmountParlaySoccer(100)
                setTotalOddsParlaySoccer(ketQua.data.totalOdds)
                setTotalTParlaySoccer(ketQua.data.t ? ketQua.data.t : 1)
            } else {
                pushToast(ketQua?.message, "warn");
            }
        } else {
            if (keyBetTabsSoccer === "2") {
                pushToast(`Your parlay bet amount exceeds the limit ${settingBet?.quantity?.parlay ? settingBet?.quantity?.parlay.toString() : ""}`, "warn");
            }
        }
        setTimeout(() => {
            pushLoading(false);
        }, 2000)
    }, 500)


    const clearAllStrightBetsSlipSoccer = () => {
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        setListBetStrightSoccer([])
        setListBetParlaySoccer([])
        setTotalStakeSoccer(0)
        setTotalOddsParlaySoccer(0)
        setTotalTParlaySoccer(1)
        setTotalAmountParlaySoccer(0)
        setHiddenBetPlaceSoccer(false)
    };

    const rStrightBetsSlipByIndexSoccer = (value: number) => {
        if (listChooseBetSoccer.length) {
            var updateListChooseBet = listChooseBetSoccer.filter((item, index) => (index !== value))
            setListChooseBetSoccer(updateListChooseBet);
        }
        if (keyBetTabsSoccer === '1') {
            if (listAddStrightBetsSlipSoccer.length) {
                var updateListStraightBet = listAddStrightBetsSlipSoccer.filter((item, index) => (index !== value))
                setListaddStrightBetsSlipSoccer(updateListStraightBet);
            }
            if (listParlayBetsSlipSoccer.length) {
                var updateListParlay = listParlayBetsSlipSoccer.filter((item, index) => (index !== value))
                setListParlayBetsSlipSoccer(updateListParlay);
            }
        } else {
            if (listAddStrightBetsSlipSoccer.length) {
                var updateListStraightBet = listAddStrightBetsSlipSoccer.filter((item, index) => (index !== value))
                setListaddStrightBetsSlipSoccer(updateListStraightBet);
            }
            if (listParlayBetsSlipSoccer.length) {
                if (listParlayBetsSlipSoccer.length === 1) {
                    setListaddStrightBetsSlipSoccer([])
                }
                var updateListParlay = listParlayBetsSlipSoccer.filter((item, index) => (index !== value))
                setListParlayBetsSlipSoccer(updateListParlay);
            }
        }
    }

    const getMinMaxBet = useDebounce(async () => {
        const ketQua = await loginApi.getMinMaxBet();
        if (ketQua?.success) {
            setMinBetStraight(ketQua?.data?.minBetStraight ? ketQua?.data?.minBetStraight : 10)
            setMaxBetStraight(ketQua?.data?.maxBetStraight ? ketQua?.data?.maxBetStraight : 10)
            setMinBetParlay(ketQua?.data?.minBetParlay ? ketQua?.data?.minBetParlay : 10)
            setMaxBetParlay(ketQua?.data?.maxBetParlay ? ketQua?.data?.maxBetParlay : 10)
        }
    }, 500)

    const handleAllClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                if (totalStakeSoccer !== 0) {
                    if (totalStakeSoccer + e <= maxBetStraight) {
                        setTotalStakeSoccer(totalStakeSoccer + e)
                        uAllAmountAddStrightBetsSlipSoccer(totalStakeSoccer + e)
                    } else {
                        // pushToast(`Total bets minimum is ${minBetStraight} and maximum is ${maxBetStraight}`, "warn");
                    }
                } else {
                    setTotalStakeSoccer(e)
                    uAllAmountAddStrightBetsSlipSoccer(e)
                }
                break;
            case "2":
                if (totalAmountParlaySoccer) {
                    if (totalAmountParlaySoccer + e <= maxBetParlay) {
                        setTotalAmountParlaySoccer(totalAmountParlaySoccer + e)
                    } else {
                        // pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
                    }
                } else {
                    setTotalAmountParlaySoccer(e)
                }
                break;
            default:
                break;
        }
    }

    const minInputStake = () => {
        switch (keyBetTabsSoccer) {
            case "1":
                setTotalStakeSoccer(Number(`${minBetStraight}`))
                uAllAmountAddStrightBetsSlipSoccer(Number(`${minBetStraight}`))
                break;
            case "2":
                setTotalAmountParlaySoccer(Number(`${minBetParlay}`))
                break;
            default:
                break;
        }
    }

    const maxInputStake = async () => {
        switch (keyBetTabsSoccer) {
            case "1":
                const ketQua = await getPresentMoney();
                if (ketQua?.success) {
                    if (Number(ketQua.data.currentMoney) >= Number(maxBetStraight)) {
                        setTotalStakeSoccer(Number(`${maxBetStraight}`))
                        uAllAmountAddStrightBetsSlipSoccer(Number(`${maxBetStraight}`))
                    } else {
                        setTotalStakeSoccer(Number(`${ketQua.data.currentMoney}`))
                        uAllAmountAddStrightBetsSlipSoccer(Number(`${ketQua.data.currentMoney}`))
                    }
                } else {
                    setTotalStakeSoccer(Number(`${maxBetStraight}`))
                    uAllAmountAddStrightBetsSlipSoccer(Number(`${maxBetStraight}`))
                }
                break;
            case "2":
                setTotalAmountParlaySoccer(Number(`${maxBetParlay}`))
                break;
            default:
                break;
        }
    }

    const handleAllNumberClickSoccerNew = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                if (e) {
                    if (e <= maxBetStraight) {
                        setTotalStakeSoccer(Number(`${e}`))
                        uAllAmountAddStrightBetsSlipSoccer(Number(`${e}`))
                    } else {
                        // pushToast(`Total bets minimum is ${minBetStraight} and maximum is ${maxBetStraight}`, "warn");
                    }
                } else {
                    setTotalStakeSoccer(e)
                    uAllAmountAddStrightBetsSlipSoccer(e)
                }
                break;
            case "2":
                if (e) {
                    if (e <= maxBetParlay) {
                        setTotalAmountParlaySoccer(Number(`${e}`))
                    } else {
                        // pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
                    }
                } else {
                    setTotalAmountParlaySoccer(e)
                }
                break;
            default:
                break;
        }
    }

    const handleAllNumberClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                if (totalStakeSoccer) {
                    if (Number(`${totalStakeSoccer}${e}`) <= maxBetStraight) {
                        setTotalStakeSoccer(Number(`${totalStakeSoccer}${e}`))
                        uAllAmountAddStrightBetsSlipSoccer(Number(`${totalStakeSoccer}${e}`))
                    }
                    // } else {
                    //   // pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
                    // }
                } else {
                    setTotalStakeSoccer(e)
                    uAllAmountAddStrightBetsSlipSoccer(e)
                }
                break;
            case "2":
                if (totalAmountParlaySoccer) {
                    // if (totalStakeSoccer <= maxBetParlay) {
                    //   setTotalAmountParlaySoccer(Number(`${totalAmountParlaySoccer}${e}`))
                    // } else {
                    //   // pushToast(`Total bets minimum is ${minBetParlay} and maximum is ${maxBetParlay}`, "warn");
                    // }
                    setTotalAmountParlaySoccer(Number(`${totalAmountParlaySoccer}${e}`))
                } else {
                    setTotalAmountParlaySoccer(e)
                }
                break;
            default:
                break;
        }
    }

    const handleAllDeleteClickSoccer = (e: number) => {
        switch (keyBetTabsSoccer) {
            case "1":
                setTotalStakeSoccer(0)
                uAllAmountAddStrightBetsSlipSoccer(0)
                break;
            case "2":
                setTotalAmountParlaySoccer(0)
                break;
            default:
                break;
        }
        // setTotalStakeSoccer(0)
        // uAllAmountAddStrightBetsSlipSoccer(0)
    }

    const uAllAmountAddStrightBetsSlipSoccer = (value: number) => {
        setListaddStrightBetsSlipSoccer((prevList) =>
            prevList.map((item) => ({ ...item, amount: value, win: getPiceWinStright(value, item.scoreChoose) }))
        );

        setListBetStrightSoccer((prevList) =>
            prevList.map((item) => ({ ...item, amount: value, win: getPiceWinStright(value, item.scoreChoose) }))
        );
    };

    const handleCheckBetStrightSoccer = useThrottle(async () => {
        const filteredArray = listAddStrightBetsSlipSoccer.filter((item) => (item.isCheck && item.amount !== 0));
        if (filteredArray.length === 0) {
            pushToast("Please select the bet and enter the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, amount, ...rest }) => ({
            ...rest,
            amount: amount,
        }));
        await setListBetStrightSoccer(updatedArray)
        cMoneyBettingSoccer(updatedArray)
    }, 1000)


    const updateBetStrightSoccer = useThrottle((listStrightBetsSlip: bettingApi.InfoAddStrightSoccerTmp[]) => {
        const filteredArray = listStrightBetsSlip.filter((item) => (item.isCheck && item.amount !== 0));
        const updatedArray = filteredArray.map(({ isCheck, amount, ...rest }) => ({
            ...rest,
            amount: amount,
            win: getPiceWinStright(amount, rest.scoreChoose)
        }));
        setListBetStrightSoccer(updatedArray)
    }, 100)

    const handleCheckBetParlaySoccer = useThrottle(async () => {
        const filteredArray = listParlayBetsSlipSoccer.filter((item) => (item.isCheck));
        if (isWarningBetParlaySoccer) {
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
            return
        }
        if (filteredArray.length <= 1) {
            pushToast("Please choose 2 or more bets!", "warn");
            return
        }
        if (totalAmountParlaySoccer === 0) {
            pushToast("Please select the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, ...rest }) => ({
            ...rest
        }));
        await setListBetParlaySoccer(updatedArray)
        cMoneyBettingSoccer(updatedArray)
    }, 1000)

    const handleDoubleCheckBetParlaySoccer = useThrottle(async (listBet?: bettingApi.InfoParlaySoccer[]) => {
        const filteredArray = listParlayBetsSlipSoccer.filter((item) => (item.isCheck));
        if (isWarningBetParlaySoccer) {
            pushToast("Parlay cannot be placed as some of your legs cannot be combined", "warn");
            return
        }
        if (filteredArray.length <= 1) {
            pushToast("Please choose 2 or more bets!", "warn");
            return
        }
        if (totalAmountParlaySoccer === 0) {
            pushToast("Please select the bet amount!", "warn");
            return
        }

        const updatedArray = filteredArray.map(({ isCheck, ...rest }) => ({
            ...rest
        }));
        setListBetParlaySoccer(updatedArray)
        const ketQua = await bettingApi.checkMoneyBetting(presentMoney, totalAmountParlaySoccer, updatedArray, keyBetTabsSoccer === "1" ? "Straight" : "Parlay");
        if (ketQua?.success) {
            aStoreParlayBetsSlipSoccer(listBet ? listBet : listBetParlaySoccer)
        } else {
            pushToast(ketQua?.message, "warn");
            if (ketQua.type === "nem") {
                setShowModalWarningSoccer(true)
            }
        }
    }, 1000)

    const [activeTabKeySoccer, setActiveTabKeySoccer] = useState("1");

    const totalGamePrematchSoccer = useRef<number>(0);
    const totalGameLivePrematchSoccer = useRef<number>(0);

    const countTotalSoccer = async (count: number) => {
    }

    const countTotalSoccerLive = async (count: number, tournament?: string) => {
    }

    const handleMenuSelectSoccer_V2 = (tournament: string, key: string) => {
        // if (listChooseGameSoccer && listChooseGameSoccer[0] === key) {
        //   setTypeListGameSoccer('main')
        //   setListChooseGameSoccer([])
        //   setSelectTournament([]);
        // } else {
        //   setTypeListGameSoccer('select')
        //   setListChooseGameSoccer([key, tournament])
        //   setSelectTournament([tournament]);
        // }

    }


    const onChangeSoccer = (key: string) => {
        setKeyBetTabsSoccer(key)
    };

    const handleRadioChangeSoccer = (value: "gameOnline" | "gameOffline") => {
        setKeyGameTabSoccer(value);
        // clearAllStrightBetsSlipSoccer()
        if (value === 'gameOnline') {
            setMenu([{
                key: '1',
                label: 'Straight',
            }])
        } else {
            setMenu((data: any) => [data, {
                key: '1',
                label: 'Straight',
            }])
            setMenu((data: any) => [...data, {
                key: '2',
                label: 'Parlay',
            }])
            // viewSettings()
        }
    };

    const [hiddenBetPlaceSoccer, setHiddenBetPlaceSoccer] = useState<boolean>(false)
    const checkUpdateOddsSoccer = async (
        id: number, point: any, prices: any[]
    ) => {
        var oddsUpdate: any
        var pointUpdate: number | string
        var listNew: any[] = []
        var listNewParlay: any[] = []
        var checkChange = 0
        await listAddStrightBetsSlipSoccer.forEach(element => {
            if (element.id === id) {
                prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdate = elementPrice.price
                        if (element.option === "X") {
                            pointUpdate = "Draw"
                        } else {
                            pointUpdate = point
                        }
                        if (element.lineBetting === "Fulltime HDP" || element.lineBetting === "First Half HDP") {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        }
                    }
                });

                listNew.push({
                    amount: element.amount,
                    dateEvent: element.dateEvent,
                    game: element.game,
                    id: element.id,
                    idGame: element.idGame,
                    isCheck: element.isCheck,
                    lineBetting: element.lineBetting,
                    option: element.option,
                    potentialPayout: element.potentialPayout,
                    scoreChoose: pointUpdate + " " + oddsUpdate,
                    // stage: element.stage,
                    teamChoose: element.teamChoose,
                    totalStake: element.totalStake,
                    tournament: element.tournament,
                    // win: element.win,
                    win: getPiceWin(element.amount, element.scoreChoose),
                    change: (pointUpdate + " " + oddsUpdate) !== element.scoreChoose ? true : false,
                })
            } else {
                listNew.push(element)
            }
        });

        await listParlayBetsSlipSoccer.forEach(element => {
            if (element.id === id) {
                prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdate = elementPrice.price
                        if (element.option === "X") {
                            pointUpdate = "Draw"
                        } else {
                            pointUpdate = point
                        }
                        if (element.lineBetting === "Fulltime HDP" || element.lineBetting === "First Half HDP") {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        }
                    }
                });
                listNewParlay.push({
                    country: element.country,
                    dateEvent: element.dateEvent,
                    game: element.game,
                    id: element.id,
                    idGame: element.idGame,
                    isCheck: element.isCheck,
                    isWarning: element.isWarning,
                    lineBetting: element.lineBetting,
                    option: element.option,
                    order: element.order,
                    scoreChoose: pointUpdate + " " + oddsUpdate,
                    stage: element.stage,
                    teamChoose: element.teamChoose,
                    tournament: element.tournament,
                    change: (pointUpdate + " " + oddsUpdate) !== element.scoreChoose ? true : false,
                })
            } else {
                listNewParlay.push(element)
            }
        });
        if (checkChange > 0) {
            // setHiddenBetPlaceSoccer(true)
        }
        setListaddStrightBetsSlipSoccer(listNew)
        setListParlayBetsSlipSoccer(listNewParlay)
    }

    const checkUpdateOddsSoccerPrematch = async (
        id: number, idOption: number, option: any, point: any, prices: any[]
    ) => {
        var oddsUpdate: any
        var pointUpdate: number | string
        var listNew: any[] = []
        var listNewParlay: any[] = []
        var checkChange = 0
        await listAddStrightBetsSlipSoccer.forEach(element => {
            if (element.idGame === id && element.id === idOption) {
                prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdate = elementPrice.price
                        if (element.lineBetting === "Fulltime 1x2" || element.lineBetting === "First Half 1x2") {
                            if (element.option === "X") {
                                pointUpdate = "Draw"
                            } else if (element.option === "1" || element.option === 1) {
                                pointUpdate = "Home"
                            } else if (element.option === "2" || element.option === 2) {
                                pointUpdate = "Away"
                            } else {
                                pointUpdate = point
                            }
                        } else if (element.lineBetting.includes("Fulltime OU") || element.lineBetting.includes("First Half OU")) {
                            if (element.option === "h") {
                                pointUpdate = point + "-Over"
                            } else {
                                pointUpdate = point + "-Under"
                            }
                        } else if (element.lineBetting.includes("Fulltime HDP") || element.lineBetting.includes("First Half HDP")) {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        } else if (element.lineBetting === "Total Goal" || element.lineBetting === "Double Chance" || element.lineBetting === "First Half Correct Score" || element.lineBetting === "Correct Score") {
                            pointUpdate = option
                        } else {
                            pointUpdate = point
                        }
                    }
                });
                element.scoreChoose = pointUpdate + " " + oddsUpdate
            }
        });

        await listParlayBetsSlipSoccer.forEach(async (element) => {
            var oddsUpdateParlay: any
            if (element.idGame === id && element.id === idOption) {

                await prices.forEach(elementPrice => {
                    if (elementPrice.option === element.option) {
                        checkChange++
                        oddsUpdateParlay = elementPrice.price

                        if (element.lineBetting === "Fulltime 1x2" || element.lineBetting === "First Half 1x2") {
                            if (element.option === "X") {
                                pointUpdate = "Draw"
                            } else if (element.option === "1" || element.option === 1) {
                                pointUpdate = "Home"
                            } else if (element.option === "2" || element.option === 2) {
                                pointUpdate = "Away"
                            } else {
                                pointUpdate = point
                            }
                        } else if (element.lineBetting.includes("Fulltime OU") || element.lineBetting.includes("First Half OU")) {
                            if (element.option === "h") {
                                pointUpdate = point + "-Over"
                            } else {
                                pointUpdate = point + "-Under"
                            }
                        } else if (element.lineBetting.includes("Fulltime HDP") || element.lineBetting.includes("First Half HDP")) {
                            if (element.option === "h") {
                                pointUpdate = -point
                            } else {
                                pointUpdate = point
                            }
                        } else if (element.lineBetting === "Total Goal" || element.lineBetting === "Double Chance" || element.lineBetting === "First Half Correct Score" || element.lineBetting === "Correct Score") {
                            pointUpdate = option
                        } else {
                            pointUpdate = point
                        }
                    }
                });
                element.scoreChoose = pointUpdate + " " + oddsUpdateParlay
            }
        });
        if (checkChange > 0) {
            // setHiddenBetPlaceSoccer(true)
        }

        // setListaddStrightBetsSlipSoccer(listNew)
        // setListParlayBetsSlipSoccer(listNewParlay)
    }

    const [listAllGameLive, setListAllGameLive] = useState<any[]>([])
    const listTournamentRef = useRef<any[]>([])
    // const listTournamentPrematchRef = useRef<any[]>([])
    const checkLoadFirstTimeLiveGame = useRef<any>()
    const arraySocketPrematch = useRef<any>()



    const [listGameMyMatch, setListGameMyMatch] = useState<any>([])
    const [listTournamentLiveMyMatch, setListTournamentLiveMyMatch] = useState<any>([])
    const [listTournamentMyMatch, setListTournamentMyMatch] = useState<any>([])

    const getMyBetRunning = async () => {
        pushLoading(true)
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const ketQua = await myBetApi.myBetRunning('All', timeZone, 10000, 0)
        if (ketQua?.success) {
            let listTournamentLive: any[] = []
            let listTournament: any[] = []
            let listGame: any[] = []
            if (ketQua.data.length) {
                await ketQua.data.forEach(async (element: any) => {
                    await element.billDetails.forEach(async (elementDetail: any) => {
                        if (!listGame.includes(elementDetail.idGame.toString())) {
                            await listGame.push(elementDetail.idGame.toString())
                        }
                        if (elementDetail.stage.toString() === "1") {

                            if (!listTournamentLive.includes(elementDetail.tournament.toString())) {
                                await listTournamentLive.push(elementDetail.tournament.toString())
                            }
                        } else {
                            if (!listTournament.includes(elementDetail.tournament.toString())) {
                                await listTournament.push(elementDetail.tournament.toString())
                            }
                        }

                    })
                });
                setListGameMyMatch(listGame)
                setListTournamentLiveMyMatch(listTournamentLive)
                setListTournamentMyMatch(listTournament)
            }
        } else {
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        // 
        pushLoading(false)
    }

    useEffect(() => {
        setTimeout(() => {
            getMyBetRunning()
        }, 1000)
        // const intervalMyMatch = setInterval(() => {
        //     getMyBetRunning()
        // }, 60000);
    }, [])

    useEffect(() => {
        if (dateFilterSoccer === "All") {
            // gAllTournamentSoccer("allgame", undefined, selectTournament)
            gAllTournamentSoccer("allgame1", undefined, selectTournament, listTournamentMyMatch)
        } else {
            gAllTournamentSoccer(undefined, undefined, selectTournament, listTournamentMyMatch)
        }
    }, [selectTournament, listTournamentMyMatch])

    useEffect(() => {
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);
        socketInstance.on('connect', () => {
            console.log('Connected to server live', socketInstance.id);
        });
        socketInstance.on('tournaments-live-soccer', async (data) => {
            const arrayOfData = data.map((obj: any) => obj.key);
            const arrayOfTournament = listTournamentSoccerNewLive.map((obj: any) => obj.key);
            if (!arraysEqual(arrayOfData, arrayOfTournament)) {
                listTournamentRef.current = data

            }
        });
        socketInstance.on('message-soccer', async (data) => {
            setListAllGameLive(data)
            checkLoadFirstTimeLiveGame.current = 1
        });

        socketInstance.on('all-tournament-soccer', async (data) => {
            setListTournamentSoccerNewSideBar(data)
        });

        socketInstance.on('message-prematch-soccer', (data: any) => {
            var endday = new Date()
            endday.setHours(23)
            endday.setMinutes(59)
            endday.setSeconds(59)
            var arrFilter = data.filter((item: any) => (new Date(`${item.realKickoffTime.replace("T", " ")}-04:00`).getTime() <= endday.getTime()))
            var arr = arrFilter.map((item: any) => ({
                ...item,
                showTime: new Date(item.startDate).getTime(),
                showTimeNow: new Date().getTime(),
                subtractTimeClient: new Date(item.startDate).getTime() - new Date().getTime(),
            }))
            arraySocketPrematch.current = arr
        });

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        var endday = new Date()
        endday.setHours(23)
        endday.setMinutes(59)
        endday.setSeconds(59)

        if (listDateFilterSoccer && listDateFilterSoccer[0] && listDateFilterSoccer[0].value && dateFilterSoccer === listDateFilterSoccer[0].value) {
            if (listDateFilterSoccer.length) {
                if (!listChooseGameSoccer[0]) {
                    listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.filter((item: any) => (new Date(`${item.realKickoffTime.replace("T", " ")}-04:00`).getTime() <= endday.getTime())).length
                    // listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.filter((item: any) => (new Date(`${item.realKickoffTime}`).getDate() === new Date().getUTCDate())).length
                } else {
                    listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.filter((item: any) => (listChooseGameSoccer.includes(item.tournament.id) && (new Date(`${item.realKickoffTime.replace("T", " ")}-04:00`).getTime() <= endday.getTime()))).length
                    // listDateFilterSoccer[0].totalMatch = arraySocketPrematch.current.filter((item: any) => (listChooseGameSoccer.includes(item.tournament.tournamentName && new Date(`${item.realKickoffTime}`).getDate() === new Date().getUTCDate()))).length
                }
            }
        }
        if (dateFilterSoccer === "All" && keyGameTabSoccer !== 'gameOnline') {
            //xóa game ở danh sách game
            if (listChooseBetSoccer.length > 0) {
                // if (keyBetTabsSoccer === '1') {
                if (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0) {
                    listAddStrightBetsSlipSoccer.map((item, itemIndex) => {
                        if (formatDateNow() === formatDate(item.dateEvent)) {
                            var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id.toString())
                            if (!listIdGameSocket.includes(item.idGame?.toString())) {
                                removeEndOfGamePrematch(Number(item.idGame))
                            }
                        }
                    })
                }
                // } else if (keyBetTabsSoccer === '2') {
                if (listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.length > 0) {
                    listParlayBetsSlipSoccer.map((item, itemIndex) => {
                        if (formatDateNow() === formatDate(item.dateEvent)) {
                            var listIdGameSocket = arraySocketPrematch.current.map((item: any) => item.id.toString())
                            if (!listIdGameSocket.includes(item.idGame?.toString())) {
                                removeEndOfGamePrematch(Number(item.idGame))
                            }
                        }
                    })
                }
                // }
            }
        }



        // update odds
        if (arraySocketPrematch.current) {
            listChooseBetSoccer.forEach(elementChooseBet => {
                arraySocketPrematch.current.forEach((elementUpdateOdd: any) => {
                    //trùng trận
                    if (elementChooseBet.idGame === elementUpdateOdd.id) {
                        elementUpdateOdd.odds.forEach((elementUpdateLineBetting: any) => {
                            //trùng linebetting
                            if (elementChooseBet.id === elementUpdateLineBetting.id) {
                                elementUpdateLineBetting.prices.forEach((elementUpdateOption: any) => {
                                    //trùng option
                                    if (elementChooseBet.option === elementUpdateOption.option) {
                                        checkUpdateOddsSoccerPrematch?.(elementUpdateOdd.id, elementUpdateLineBetting.id, elementUpdateOption.option, elementUpdateLineBetting.point, elementUpdateLineBetting.prices)
                                    }
                                })
                            }
                        })
                    }
                });
            });
        }
    }, [arraySocketPrematch.current])

    useEffect(() => {
        if (listChooseGameSoccer.length === 0) {
            setListTournamentSoccerNewLive(listTournamentRef.current.filter((item: any) => (listTournamentLiveMyMatch.includes(item.tournament))))
        } else {
            setListTournamentSoccerNewLive(listTournamentRef.current.filter((item: any) => (listChooseGameSoccer.includes(item.key) && listTournamentLiveMyMatch.includes(item.tournament))))
        }
    }, [listTournamentRef.current]);

    const checkFirstTime = useRef(0)
    useEffect(() => {
        if (listChooseGameSoccer.length === 0) {
            setTotalGameliveSoccer(listAllGameLive.length)
            // && checkLoadFirstTimeLiveGame !== 0
            if (selectTournament.length === 0) {
                if (listAllGameLive.length === 0 && checkFirstTime.current === 0 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                    handleRadioChangeSoccer("gameOffline")
                    setDateFilterSoccer("All")
                    gAllTournamentSoccer("allgame", undefined, selectTournament)
                    checkFirstTime.current = 1
                }
            } else {
                if ((listAllGameLive.filter(item => selectTournament.includes(item.tournament.id)).length === 0 && listAllGameLive.filter(item => selectTournament.includes(item.tournament.tournament)).length === 0) && checkFirstTime.current === 0 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                    handleRadioChangeSoccer("gameOffline")
                    setDateFilterSoccer("All")
                    gAllTournamentSoccer("allgame", undefined, selectTournament)
                    checkFirstTime.current = 1
                }
            }

        } else {
            setTotalGameliveSoccer(listAllGameLive.filter((item) => (listChooseGameSoccer.includes(item.tournament.id))).length)
        }
        if (listAddStrightBetsSlipSoccer.length > 0 && listAllGameLive.length > 0) {
            listAddStrightBetsSlipSoccer.forEach(element => {
                var check = 0
                var blockBetslip = 0
                listAllGameLive.forEach(elementSocket => {
                    if (element.idGame === elementSocket.id) {
                        check = 1
                    }
                    elementSocket.odds.forEach((elementOddsSocket: any) => {
                        if (element.id === elementOddsSocket.id) {
                            elementOddsSocket.prices.forEach((elementPricesSocket: any) => {
                                if (element.lineBetting === "OddEven") {
                                    if (element.option === (elementPricesSocket.option === 'a' ? "Even" : "Odd")) {
                                        blockBetslip = 1
                                    }
                                } else {
                                    if (element.option === elementPricesSocket.option) {
                                        blockBetslip = 1
                                    }
                                }

                            });
                        }
                    });

                });
                if (keyGameTabSoccer === "gameOnline") {
                    setTimeout(() => {
                        if (check === 0 || blockBetslip === 0) {
                            removeEndOfGame(element.idGame)
                        }
                    }, 100)
                }

            });
        }
        if (selectTournament.length === 0) {
            if (keyGameTabSoccer === 'gameOnline' && listAllGameLive.length === 0 && checkFirstTime.current === 1 && checkLoadFirstTimeLiveGame.current !== 0 && checkLoadFirstTimeLiveGame.current !== undefined) {
                //chuyển qua tab all khi hết live
                handleRadioChangeSoccer("gameOffline")
                setDateFilterSoccer("All")
                gAllTournamentSoccer("allgame", undefined, selectTournament)
            }
        } else {
            if (keyGameTabSoccer === 'gameOnline' && listAllGameLive.filter(item => selectTournament.includes(item.tournament.tournamentName)).length === 0) {
                //chuyển qua tab all khi hết live
                handleRadioChangeSoccer("gameOffline")
                setDateFilterSoccer("All")
                gAllTournamentSoccer("allgame", undefined, selectTournament)
            }
        }
    }, [listAllGameLive])

    const removeEndOfGame = (idGame: string | number | undefined) => {
        if (keyGameTabSoccer === 'gameOnline') {
            setListChooseBetSoccer([])
            setListaddStrightBetsSlipSoccer([])
        } else {
            const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
            setListChooseBetSoccer(updatedList)

            const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
            setListaddStrightBetsSlipSoccer(updatedListStraight)
        }
    }
    const removeEndOfGamePrematch = useThrottle((idGame: number) => {
        const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
        setListChooseBetSoccer(updatedList)

        const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
        setListaddStrightBetsSlipSoccer(updatedListStraight)

        const updatedListParlay = listParlayBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
        setListParlayBetsSlipSoccer(updatedListParlay)
    }, 50)

    const blockGameID = useThrottle((idGame: string | number | undefined) => {
        if (listAddStrightBetsSlipSoccer.length > 0 || listChooseBetSoccer.length > 0) {
            const updatedList = listChooseBetSoccer.filter((bet) => !(bet.idGame === idGame))
            setListChooseBetSoccer(updatedList)

            const updatedListStraight = listAddStrightBetsSlipSoccer.filter((bet) => !(bet.idGame === idGame))
            setListaddStrightBetsSlipSoccer(updatedListStraight)
        }
    }, 1000)

    const checkUpdateStrightBetsSlip = useDebounce((id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number, color?: string) => {
        setUpdateOdds(true)
        var totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        const updatedList = listAddStrightBetsSlipSoccer?.map(item => {
            if (item.id === id && item.option === option) {
                return { ...item, scoreChoose: scoreChoose, color: color, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
            }
            return item;
        });
        setListaddStrightBetsSlipSoccer(updatedList)
        if (listBetStrightSoccer.length) {
            const updatedListBet = listBetStrightSoccer?.map(item => {
                if (item.id === id && item.option === option) {
                    return { ...item, scoreChoose: scoreChoose, color: color, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                }
                return item;
            });
            setListBetStrightSoccer(updatedListBet)
        }
    }, 500)

    const checkUpdateStrightBetsSlipPrematch = useDebounce(async (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => {
        setUpdateOdds(true)
        if (dateFilterSoccer === listDateFilterSoccer[0].value) {
            if (keyBetTabsSoccer === "1") {
                var totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
                    return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
                }, 0);
                const updatedList = listAddStrightBetsSlipSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                    }
                    return item;
                });
                setListaddStrightBetsSlipSoccer(updatedList)
                updateBetStrightSoccer(updatedList)
            } else {
                const updatedList = listParlayBetsSlipSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose, homeScore: homeScore, awayScore: awayScore, win: getPiceWinStright(totalAmountTmp, item.scoreChoose) };
                    }
                    return item;
                });
                const updatedListChooseBetSoccer = listChooseBetSoccer?.map(item => {
                    if (item.id === id && item.option === option) {
                        return { ...item, scoreChoose: scoreChoose };
                    }
                    return item;
                });
                await setListParlayBetsSlipSoccer(updatedList)
                await setListChooseBetSoccer(updatedListChooseBetSoccer)
            }
        }
    }, 500)



    const clearTournament = async () => {
        setListChooseGameSoccer([])
        setSelectTournament([])
    }

    const chooseTournamentArray = async (tournament: string, key: number) => {
        //phần web
        if (listChooseGameSoccer.indexOf(key) > -1) {
            await setListChooseGameSoccer(listChooseGameSoccer.filter((i) => (i !== key)))
            await setSelectTournament(selectTournament.filter((i) => (i !== tournament)))
        } else {
            await setListChooseGameSoccer((data: any) => [...data, key])
            await setSelectTournament((data: any) => [...data, tournament])
        }
        if (listChooseGameSoccer.length) {
            setTypeListGameSoccer('main')
        } else {
            setTypeListGameSoccer('select')
        }
    }

    const changeKeyBetTabsSoccer = (e: any) => {
        setKeyBetTabsSoccer(e)
    }
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 8;

    const nextSlide = () => {
        if (currentIndex < listTournamentSoccerNewSideBar.length - itemsToShow) {
            setCurrentIndex(currentIndex + itemsToShow);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsToShow);
        }
    };

    const dataTournament_2: homePage_V2Api.InfoTournamentSoccer_V2[] = listTournamentSoccerNewSideBar;
    const heightTournament = dataTournament_2.slice(currentIndex, currentIndex + itemsToShow).length > 4

    const [openDrawerBestSlip, setOpenDrawerBestSlip] = useState(false)

    const [offsetTop, setOffsetTop] = useState(0);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(async (data) => {
                setIpDevice(String(data.ip))
                await fetch('http://www.geoplugin.net/json.gp?ip=' + String(data.ip)).then(res => res.json()).then(data => {
                    setLocationDevice(data.geoplugin_city ? data.geoplugin_city : "")
                })
            })
            .catch(error =>
                console.log("Can't get IP and Location from device")
            )
    }, [])

    useEffect(() => {
        resetData()
        gAllTournamentSoccer()
        gAllTournamentSoccerLive()
    }, [])

    useEffect(() => {
        viewSettings()
    }, [keyGameTabSoccer])

    useEffect(() => {
        pushLoading(true)
        totalGamePrematchSoccer.current = 0
        setTotalGameSoccer(0)
        setTimeout(() => {
            pushLoading(false)
        }, 1000)
        scrollTop()
    }, [dateFilterSoccer])

    useEffect(() => {
        if (keyBetTabsSoccer === '1') {
            if (listAddStrightBetsSlipSoccer.length) {
                setTotalBetSoccer(false)
            } else {
                setTotalBetSoccer(true)
            }
        }
        const totalAmountTmp = listAddStrightBetsSlipSoccer.reduce((accumulator, currentItem) => {
            return currentItem.isCheck ? accumulator + currentItem.amount : accumulator;
        }, 0);
        setTotalAmountStrightSoccer(totalAmountTmp)
    }, [listAddStrightBetsSlipSoccer, keyBetTabsSoccer])

    useEffect(() => {
        if (keyBetTabsSoccer === '2') {
            if (listParlayBetsSlipSoccer.length > 1) {
                setTotalBetSoccer(false)
            } else {
                setTotalBetSoccer(true)
            }
        }
    }, [listParlayBetsSlipSoccer, keyBetTabsSoccer])

    useEffect(() => {
        pushLoading(true)
        settingsBet()
        switch (true) {
            case listChooseBetSoccer.length > 0:
                if (keyGameTabSoccer === "gameOffline") {
                    if (settingBet?.quantity.parlay && (settingBet?.quantity.parlay > listParlayBetsSlipSoccer.length || (settingBet?.quantity.parlay === listParlayBetsSlipSoccer.length && settingBet?.quantity.parlay - 1 === listChooseBetSoccer.length))) {
                        aParlayBetsSlipSoccer()
                    }
                    if (listChooseBetSoccer.length > 1 && keyBetTabsSoccer !== "2" && !updateOdds)
                        setKeyBetTabsSoccer("2")
                }
                break;
            case listChooseBetSoccer.length === 1:
                rParlayBetsSlipSoccer(listChooseBetSoccer[0].game)
                setTotalOddsParlaySoccer(0)
                setTotalTParlaySoccer(1)
                setTotalAmountParlaySoccer(0)
                if (!updateOdds)
                    setKeyBetTabsSoccer("1")
                break;

            case listChooseBetSoccer.length === 0:
                setListaddStrightBetsSlipSoccer([])
                setListParlayBetsSlipSoccer([])
                break;
            default:
                setListaddStrightBetsSlipSoccer([])
                setListParlayBetsSlipSoccer([])
                setTotalOddsParlaySoccer(0)
                setTotalTParlaySoccer(1)
                setTotalAmountParlaySoccer(0)
                if (!updateOdds)
                    setKeyBetTabsSoccer("1")
                break;
        }
        pushLoading(false)
    }, [listChooseBetSoccer])

    // useEffect(() => {
    //     if (dateFilterSoccer !== "All") {
    //         if (listChooseGameSoccer.length)
    //             gListOfGameByCountryAndGameSoccer()
    //         else {

    //             gAllTournamentSoccerLive()
    //         }
    //     }
    // }, [listChooseGameSoccer])

    useEffect(() => {
        if (listParlayBetsSlipSoccer?.length && keyBetTabsSoccer === '2' && listChooseBetSoccer.length >= 2) {
            const arr = listParlayBetsSlipSoccer.filter(item => item.isCheck === true);
            checkConditionsBetParlaySoccer(arr)
        }
    }, [listParlayBetsSlipSoccer])

    useEffect(() => {
        if (location.pathname === "/") {
            setActiveTabKeySoccer("1");
        } else {

        }
    }, [location]);

    useEffect(() => {
        setListChooseBetSoccer([])
        setListaddStrightBetsSlipSoccer([])
        setListParlayBetsSlipSoccer([])
        if (keyGameTabSoccer === 'gameOnline') {
            setKeyBetTabsSoccer('1')
        }
        scrollTop()
    }, [keyGameTabSoccer])

    useEffect(() => {
        getMinMaxBet()
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                // Large screen (desktop)
                setOffsetTop(44);
            } else {
                // Small screen (mobile)
                setOffsetTop(75);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        (window as any).Prop = openDrawerBestSlip

        const checkScreenWidth = () => {
            if (window.innerWidth <= 1365 && (window as any).Prop) {
                document.getElementById("body")!.style.overflow = "hidden";
            } else {
                document.getElementById("body")!.style.overflow = "auto";
            }
        };
        checkScreenWidth()
        window.addEventListener("resize", checkScreenWidth);
        return () => {
            window.removeEventListener("resize", checkScreenWidth);
        };
    }, [openDrawerBestSlip])

    return (
        <>
            <Layout >
                <div className='bg-[#131620] h-full w-full  lg:!min-h-[100vh] pt-[34px] xl:pb-[34px] xs:pb-[72px] pl-1 flex flex-col  relative'>
                    <div className="fixed z-[9999] w-[100vw] flex justify-center top-10 xs:left-0">
                        <ToastGlobal />
                    </div>
                    {window.innerWidth < 1200 && (
                        <ModalBetSlip
                            openDrawer={openDrawerBestSlip}
                            closeDrawer={() => setOpenDrawerBestSlip(false)}
                            menu={menu}
                            keyBetTabs={keyBetTabsSoccer}
                            keyGameTab={keyGameTabSoccer}
                            chooseElement=''
                            totalStake={totalStakeSoccer}
                            listAddStrightBetsSlip={listAddStrightBetsSlipSoccer}
                            listParlayBetsSlip={listParlayBetsSlipSoccer}
                            setKeyBetTabs={(e) => changeKeyBetTabsSoccer(e)}
                            rStrightBetsSlipByIndex={rStrightBetsSlipByIndexSoccer}
                            clearAll={clearAllStrightBetsSlipSoccer}
                            setListaddStrightBetsSlip={setListaddStrightBetsSlipSoccer}
                            setListParlayBetsSlip={setListParlayBetsSlipSoccer}
                            setTotalStake={setTotalStakeSoccer}

                            showTotalBet={showTotalBetSoccer}
                            showTotalStake={showTotalStakeSoccer}
                            showTotalStake_V2={showTotalStakeSoccer_V2}
                            isWarningBetParlay={isWarningBetParlaySoccer}
                            textWarningBetParlaySoccer={textWarningBetParlaySoccer}
                            totalOddsParlay={totalOddsParlaySoccer}
                            totalTParlay={totalTParlaySoccer}
                            totalAmountParlay={totalAmountParlaySoccer}
                            numberStake={totalStakeSoccer}

                            handleClick={handleAllClickSoccer}
                            handleNumberClick={handleAllNumberClickSoccer}
                            handleChangeInput={handleAllNumberClickSoccerNew}
                            minInputStake={minInputStake}
                            maxInputStake={maxInputStake}
                            handleDeleteClick={handleAllDeleteClickSoccer}
                            showHideTotalStake={showHideTotalStakeSoccer}
                            showHideTotalStake_V2={showHideTotalStakeSoccer_V2}
                            handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                            handleCheckBetStright={handleCheckBetStrightSoccer}
                            handleCheckBetParlay={handleCheckBetParlaySoccer}
                            hiddenBetPlaceSoccer={hiddenBetPlaceSoccer}
                            minBet={keyBetTabsSoccer === "1" ? minBetStraight : minBetParlay}
                            maxBet={keyBetTabsSoccer === "1" ? maxBetStraight : maxBetParlay}
                        />
                    )}
                    <ModalWarning
                        showModal={showModalWarningSoccer}
                        setShowModal={setShowModalWarningSoccer}
                    />
                    <ModalTourName
                        dataTournament_2={dataTournament_2}
                        showModalTourName={showModalTourName}
                        listChooseTournament={listChooseGameSoccer}
                        chooseTournament={chooseTournamentArray}
                        closeModal={() => setShowModalTourName(false)}
                        clearTournament={clearTournament}
                    />
                    <DrawerTourNameMobile
                        dataTournament_2={dataTournament_2}
                        openDrawer={showDrawerTourNameMobile}
                        listChooseTournament={listChooseGameSoccer}
                        chooseTournament={chooseTournamentArray}
                        closeDrawer={() => setShowDrawerTourNameMobile(false)}
                        clearTournament={clearTournament}
                    />
                    <HeadNavi_V2
                        openDrawer={openDrawerBestSlip}
                        closeDrawer={() => setOpenDrawerBestSlip(false)}
                        tournaments={tournamentsSoccer}
                        setTournaments={handelChooseTournamentsSoccer}
                    />
                    <div className='flex flex-col pr-1 xs:w-full xl:w-[80%]'>
                        {/* <div className=' bg-[#131620] xs:pt-9 xl:pt-2 w-full xs:hidden md:flex '>
                            <Banner
                                sportId={1}
                            />
                        </div> */}
                        <div className={`w-full  xs:pt-9 md:pt-0`}>
                            <div className={`xs:hidden xl:block xl:w-[20%]  align-top bottom-0 fixed z-20 right-0 overflow-y-hidden top-[44px]`}>
                                <div className={`flex flex-col justify-between border border-[#202531]  bg-[#131620]   w-full ${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? 'h-full' : ''}`}>
                                    <div className={`flex flex-col  ${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? 'h-[calc(100vh-200px)]' : ''}`}>
                                        {/* 'h-[calc(70vh-200px)]' */}
                                        <span className='flex justify-between pb-1 '>
                                            <div className="flex items-center gap-2">
                                                <img src={start} alt="Crown" className='w-[35px] h-[35px]' />
                                                <p className="font-bold-GT-America lg:text-[10px] xl:text-[10px] xl2:text-sm text-white uppercase">Bet Slip</p>
                                            </div>
                                            <div className='flex items-center gap-1 pr-3'>
                                                <div className={`${listAddStrightBetsSlipSoccer.length > 0 || listParlayBetsSlipSoccer.length > 0 ? 'flex items-center gap-1' : 'hidden '}`}
                                                    onClick={clearAllStrightBetsSlipSoccer}
                                                >
                                                    <img src={Trash} alt="" />
                                                    <span className='lg:text-[10px] xl:text-[10px] xl2:text-xs text-white uppercase fw-[500]'>Remove All</span>
                                                </div>

                                                <span className={`h-5 w-5 rounded-[50%]   lg:text-[10px] xl:text-[10px] xl2:text-xs flex items-center justify-center ${listAddStrightBetsSlipSoccer.length === 0 ? 'bg-[#202531] text-white' : 'bg-[#D4B962] text-[#131620]'}`}>
                                                    {keyBetTabsSoccer === '1' ? listAddStrightBetsSlipSoccer.length.toString() : listParlayBetsSlipSoccer.length.toString()}
                                                </span>
                                            </div>
                                        </span>

                                        <div className={`tab-bet-soccer ${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'}`}>
                                            <Tabs items={menu}
                                                onChange={onChangeSoccer}
                                                activeKey={keyBetTabsSoccer}

                                            />
                                            <EmtyBetSlip activeKey={keyBetTabsSoccer} />
                                        </div>
                                    </div>

                                    <div className={`${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'} border-t border-[#202531] flex flex-col gap-3 px-6 pb-5`}>
                                        <div className='flex justify-between pt-3'>
                                            <div className='flex flex-col gap-4'>
                                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>
                                                    Total stake
                                                </span>
                                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>-</span>
                                            </div>
                                            <div className='flex flex-col gap-4'>
                                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>
                                                    Total win
                                                </span>
                                                <span className='lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base fw-[400] text-[#E4E7F1]'>-</span>
                                            </div>
                                        </div>
                                        <button className={`font-bold-GT-America rounded-[2px]  uppercase w-full  py-[10px] ${listAddStrightBetsSlipSoccer.length > 0 ? 'bg-[#D4B962] text-[#20273D]' : 'bg-[#E4E7F1] text-[#666E97]'}`}>
                                            Place bet
                                        </button>
                                    </div>

                                    <div className={`${listAddStrightBetsSlipSoccer.length === 0 && listParlayBetsSlipSoccer.length === 0 ? '' : 'hidden'}`}>

                                    </div>
                                    <div
                                        hidden={!listAddStrightBetsSlipSoccer.length && !listParlayBetsSlipSoccer.length}
                                        className={` overflow-hidden ${keyGameTabSoccer === "gameOffline" ? "h-[calc(100vh-320px)]" : "h-[calc(100vh-280px)]"}`}
                                    >
                                        <BetTabsSoccer
                                            menu={menu}
                                            keyBetTabs={keyBetTabsSoccer}
                                            keyGameTab={keyGameTabSoccer}
                                            chooseElement=''
                                            totalStake={totalStakeSoccer}
                                            listAddStrightBetsSlip={listAddStrightBetsSlipSoccer}
                                            listParlayBetsSlip={listParlayBetsSlipSoccer}
                                            setKeyBetTabs={(e) => changeKeyBetTabsSoccer(e)}
                                            rStrightBetsSlipByIndex={rStrightBetsSlipByIndexSoccer}
                                            clearAll={clearAllStrightBetsSlipSoccer}
                                            setListaddStrightBetsSlip={setListaddStrightBetsSlipSoccer}
                                            setListParlayBetsSlip={setListParlayBetsSlipSoccer}
                                            setTotalStake={setTotalStakeSoccer}
                                        />
                                    </div>
                                </div>

                                <TotalStakeComponentV2

                                    keyBetTabs={keyBetTabsSoccer}
                                    showTotalBet={showTotalBetSoccer}
                                    showTotalStake={showTotalStakeSoccer}
                                    showTotalStake_V2={showTotalStakeSoccer_V2}
                                    isWarningBetParlay={isWarningBetParlaySoccer}
                                    textWarningBetParlaySoccer={textWarningBetParlaySoccer}
                                    totalOddsParlay={totalOddsParlaySoccer}
                                    totalTParlay={totalTParlaySoccer}
                                    totalAmountParlay={totalAmountParlaySoccer}
                                    numberStake={totalStakeSoccer}
                                    listAddStrightBetsSlip={listAddStrightBetsSlipSoccer}
                                    listParlayBetsSlip={listParlayBetsSlipSoccer}

                                    handleClick={handleAllClickSoccer}
                                    handleNumberClick={handleAllNumberClickSoccer}
                                    handleChangeInput={handleAllNumberClickSoccerNew}
                                    minInputStake={minInputStake}
                                    maxInputStake={maxInputStake}
                                    handleDeleteClick={handleAllDeleteClickSoccer}
                                    showHideTotalStake={showHideTotalStakeSoccer}
                                    showHideTotalStake_V2={showHideTotalStakeSoccer_V2}
                                    handleCheckQuantityMoneyStraight={handleCheckQuantityMoneyStraight}
                                    handleCheckBetStright={handleCheckBetStrightSoccer}
                                    handleCheckBetParlay={handleCheckBetParlaySoccer}
                                    minBet={keyBetTabsSoccer === "1" ? minBetStraight : minBetParlay}
                                    maxBet={keyBetTabsSoccer === "1" ? maxBetStraight : maxBetParlay}
                                />

                            </div>
                            <div className='w-full  xs:pl-0 xl:pl-[110px]'>
                                <Affix offsetTop={offsetTop}>
                                    <div className='w-full'>
                                        <div className='bg-[#131620] '>
                                            <div className='flex flex-col rounded-2 rounded-[6px] '>
                                                <div className='bg-gradient-A98F34 flex justify-between items-center h-[38px] px-3'>
                                                    <div className="xs:flex xl:hidden items-center gap-2" onClick={() => setShowDrawerTourNameMobile(true)}>
                                                        <span className='xs:text-sm  xl2:text-base  font-bold-GT-America  text-white uppercase '>Game list</span>
                                                        <img src={filter_icon} alt="" className=''
                                                        />
                                                    </div>
                                                    <span className='xs:hidden xl:block xs:text-sm  xl2:text-base  font-bold-GT-America  text-white uppercase '>Game list</span>
                                                    <div className='flex items-center gap-2'>
                                                        <button
                                                            className=' xl:flex items-center gap-2 lg:text-xs xl2:text-sm  xs:hidden  xl:mb-1 uppercase text-[#E4E7F1] border border-[#202531] bg-[#131620] rounded-md px-4 py-1 hover:bg-[#DBBF64] hover:text-[#131620]'
                                                            onClick={() => setShowModalTourName(true)}
                                                        >
                                                            <FilterOutlined />
                                                            tournament
                                                        </button>
                                                        <div className='flex items-center gap-[10px] pl-[10px] border-l border-[#202531] cursor-pointer' onClick={resetBet}>
                                                            <img src={reload} alt="" className='h-[20px]' />

                                                            {time && time > 0
                                                                ?
                                                                (<span className='text-white'>{time}</span>)
                                                                :
                                                                (<span className='xs:text-xs lg:text-[10px] xl:text-xs xl2:text-sm fw-[400]  text-white uppercase'>Reload</span>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <DayTimeMyMatch
                                                    showLive={listTournamentSoccerNewLive.length ? true : false}
                                                    listDateFilterSoccer={listDateFilterSoccer}
                                                    dateFilterSoccer={dateFilterSoccer}
                                                    changeDateFilter={changeDateFilter}
                                                    keyGameTab={keyGameTabSoccer}
                                                    totalGame={totalGameSoccer}
                                                    totalGameOnLine={totalGameLiveSoccer}
                                                    totalAllGame={totalAllGameSoccer}
                                                    onRadioChange={handleRadioChangeSoccer}
                                                />
                                            </div>
                                        </div>
                                        <div className='xs:hidden md:block'>
                                            {keyGameTabSoccer === "gameOnline"
                                                ? (
                                                    <div className='w-full flex justify-end bg-[#131620] border border-[#414961] rounded-md'>
                                                        <div className=" flex gap-1 w-[calc(100%-140px)] pr-2">
                                                            <div className="flex flex-col gap-1 w-[49.5%]">
                                                                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase border-b border-b-[#1D212D] py-2 flex justify-center">Full time</span>
                                                                <div className="flex justify-around">
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">HDP</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">OU</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">1X2</span>
                                                                </div>

                                                            </div>
                                                            <div className="flex flex-col gap-1 w-[49.5%]">
                                                                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase border-b border-b-[#1D212D] py-2 flex justify-center">First half</span>
                                                                <div className="flex justify-around">
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">HDP</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">OU</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">1X2</span>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col gap-1 w-[3%] whitespace-nowrap">
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <div className='w-full flex justify-end bg-[#131620] border border-[#414961] rounded-md'>
                                                        <div className="flex gap-1 w-[calc(100%-140px)] pr-2">
                                                            <div className="w-[56%] flex flex-col gap-1">
                                                                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase border-b border-b-[#1D212D] py-2 flex justify-center">Full time</span>
                                                                <div className="flex justify-around">
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/4 flex justify-center">HDP</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/4 flex justify-center">OU</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/4 flex justify-center">1X2</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/4 flex justify-center">OE</span>
                                                                </div>
                                                            </div>
                                                            <div className="w-[43%] flex flex-col gap-1">
                                                                <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase border-b border-b-[#1D212D] py-2 flex justify-center">First half</span>
                                                                <div className="flex justify-around">
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">HDP</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">OU</span>
                                                                    <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase py-[7px] w-1/3 flex justify-center">1X2</span>
                                                                </div>
                                                            </div>
                                                            <div className="w-[3%] flex flex-col gap-1 whitespace-nowrap">
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>

                                    </div>
                                </Affix>
                                <div className=' w-full  h-full pt-2'>
                                    <div className='w-full flex flex-col  rounded-sm gap-3 '>
                                        {keyGameTabSoccer === "gameOffline" && listTournamentSoccerNew && listTournamentSoccerNew.map((item, i) => (
                                            <LazyLoad height={20} key={i}>
                                                <div
                                                    key={item.tournament}
                                                >
                                                    <Collapse
                                                        key={item.tournament}
                                                        defaultActiveKey={[i]}
                                                        expandIconPosition='end'
                                                        className=' w-full collapse-league_V2'

                                                        expandIcon={
                                                            (expanded) => (
                                                                expanded.isActive ? (
                                                                    <div className='w-full flex justify-center gap-2 '>
                                                                        <span className="xs:text-[10px] lg:text-[10px] xl:text-xs  uppercase  fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                                        <img src={Collapse_img} alt="" />
                                                                    </div>
                                                                ) : (
                                                                    <div className='w-full flex justify-center gap-2 '>
                                                                        <span className="xs:text-[10px] lg:text-[10px] xl:text-xs  uppercase  fw-[400] !text-[#E4E7F1]">expand</span>
                                                                        <img src={Expand} alt="" />
                                                                    </div>
                                                                )
                                                            )


                                                        }
                                                        items={[{
                                                            key: i,
                                                            label: <div className='text-[#D4B962] fw-[500]  flex items-center uppercase xs:text-sm  xl:text-base '>
                                                                <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                                </div>
                                                                {item.tournament}
                                                            </div>,
                                                            children:
                                                                < MyMatchGameSoccer
                                                                    // loadTournament={loadTournament}
                                                                    // setLoadTournament={() => setLoadTournament('')}
                                                                    keyBetTabsSoccer={keyBetTabsSoccer}
                                                                    tournament={item.tournament}
                                                                    item={item}
                                                                    listChooseBet={listChooseBetSoccer}
                                                                    chooseBetSoccer={checkAddStrightBetsSlipSoccer}
                                                                    listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                                    listParlayBetsSlipSoccer={listParlayBetsSlipSoccer}
                                                                    countTotalSoccer={countTotalSoccer}
                                                                    listDateFilterSoccer={listDateFilterSoccer}
                                                                    dateFilterSoccer={dateFilterSoccer}
                                                                    resetBetGame={resetBetGame}
                                                                    updateBetGameReset={updateBetGameReset}
                                                                    checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlipPrematch}
                                                                    removeEndOfGamePrematch={removeEndOfGamePrematch}
                                                                    setTotalGameSoccer={setTotalGameSoccer}
                                                                    typeListGameSoccer={typeListGameSoccer}
                                                                    listGameMyMatch={listGameMyMatch}
                                                                />
                                                        }]}
                                                    />
                                                </div>

                                            </LazyLoad>
                                        ))}
                                        {
                                            keyGameTabSoccer === "gameOnline" && listTournamentSoccerNewLive && listTournamentSoccerNewLive.map((item, i) => (
                                                <LazyLoad height={20} key={i}>
                                                    <div
                                                        key={item.tournament}
                                                    >

                                                        <Collapse
                                                            key={`Live-${item.tournament}`}

                                                            defaultActiveKey={[`Live-${item.tournament}`]}
                                                            expandIconPosition='end'
                                                            className=' w-full collapse-league_V2'

                                                            expandIcon={
                                                                (expanded) => (
                                                                    expanded.isActive ? (
                                                                        <div className='w-full flex justify-center gap-2 '>
                                                                            <span className="uppercase text-xs fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                                            <img src={Collapse_img} alt="" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className='w-full flex justify-center gap-2 '>
                                                                            <span className="uppercase text-xs fw-[400] !text-[#E4E7F1]">expand</span>
                                                                            <img src={Expand} alt="" />
                                                                        </div>
                                                                    )
                                                                )
                                                            }
                                                            items={[{
                                                                key: `Live-${item.tournament}`,
                                                                label: <div className='text-[#D4B962] fw-[500] text-sm flex items-center uppercase'>
                                                                    <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                                    </div>
                                                                    {item.tournament}
                                                                </div>,
                                                                children:
                                                                    <MyMatchGameSoccerLive
                                                                        tournament={item.tournament}
                                                                        item={item}
                                                                        listChooseBet={listChooseBetSoccer}
                                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                                        chooseBetSoccer={checkAddStrightBetsSlipSoccer}
                                                                        countTotalSoccer={countTotalSoccerLive}
                                                                        checkUpdateOdds={checkUpdateOddsSoccer}
                                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                                        blockGameID={blockGameID}
                                                                        listGameMyMatch={listGameMyMatch}
                                                                    />
                                                            }]}
                                                        />


                                                    </div>
                                                </LazyLoad>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* footer mobile */}
                    <div className="xs:block xl:hidden">
                        <FooterMobile
                            countBetslip={listChooseBetSoccer.length}
                            setOpenDrawerBestSlip={() => setOpenDrawerBestSlip(true)}
                        />
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default MyMatch;