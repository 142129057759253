/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { InfoTournamentSoccer } from '../../../../../api/homePage/homePageApi';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import { checkNoData } from '../../../../../until';
import { LogoWithoutText, video_2 } from '../../../../../components/imgExport';
import useDebounce from '../../../../../hook/useDebounce';
import LazyLoad from 'react-lazyload';
import { API_URL_SOCCER } from '../../../../../env';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import io from 'socket.io-client';
import useThrottle from '../../../../../hook/useThrottle';
import SoccerTopV3New from './SoccerTopV3New';
interface GameSoccerProps {
    keyBetTabsSoccer?: string
    loadTournament?: string
    setLoadTournament?: () => void
    tournament: string
    item: InfoTournamentSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    listParlayBetsSlipSoccer?: bettingApi.InfoParlaySoccerTmp[]
    dateFilterSoccer?: string
    resetBetGame?: boolean
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
    typeListGameSoccer?: "main" | "select"

    // id: string
    // tournament: string
    // itemGame: InfoGameCR
    // listChooseBet: ChooseBet[]

    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string
    ) => void
    countTotalSoccer?: (
        count: number
    ) => void
    updateBetGameReset?: () => void
    checkUpdateOdds?: (id: number, point: number, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => void
    blockGameID?: (id: number | string) => void
    removeEndOfGamePrematch?: (id: number) => void
    setTotalGameSoccer?: (total: number) => void
}

const GameSoccer_V2New: React.FC<GameSoccerProps> = ({
    keyBetTabsSoccer, loadTournament, tournament, item, listChooseBet, listAddStrightBetsSlipSoccer, listParlayBetsSlipSoccer, dateFilterSoccer, resetBetGame, listDateFilterSoccer, typeListGameSoccer,
    chooseBetSoccer, countTotalSoccer, updateBetGameReset, setLoadTournament, checkUpdateOdds, checkUpdateStrightBetsSlip, removeEndOfGamePrematch, setTotalGameSoccer

}) => {
    const pushToast = useToast();

    const [listGameSoccer, setListGameSoccer] = useState<homePage_V2Api.InfoGameSoccer[]>([])
    const [socket, setSocket] = useState<any>(null);
    const gGameSoccerByTournamentName = useDebounce(async () => {
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (dateFilterSoccer === "all") {
            const ketQua = await homePage_V2Api.allGameByTournamentSoccerSbobet(item.tournament, "", timeZone);
            if (ketQua?.success) {
                let arrFilter = ketQua.data
                // let arrFilter = ketQua.data.filter((item: any) => (new Date(item.realKickoffTime).getDate() === new Date().getUTCDate()))
                const updateList = arrFilter.map((item: any) => ({
                    ...item,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
            } else {
                setListGameSoccer([]);
                pushToast(ketQua?.message, "warn");
            }
        } else {
            const ketQua = await homePage_V2Api.getGameSoccerByTournamentName(item.tournament, dateFilterSoccer, timeZone);
            if (ketQua?.success) {
                countTotalSoccer?.(ketQua.data.length)
                var endday = new Date()
                endday.setHours(23)
                endday.setMinutes(59)
                endday.setSeconds(59)
                let arrFilter
                if (listDateFilterSoccer && (dateFilterSoccer === listDateFilterSoccer[0]?.value || (listDateFilterSoccer[0]?.value && dateFilterSoccer?.slice(0, 10) === listDateFilterSoccer[0]?.value?.slice(0, 10)))) {
                    arrFilter = ketQua.data.filter((item: any) => (new Date(`${item.realKickoffTime.replace("T", " ")}-04:00`).getTime() <= endday.getTime()))

                } else {
                    arrFilter = ketQua.data
                }
                const updateList = arrFilter.map((item: any) => ({
                    ...item,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
            } else {
                setListGameSoccer([]);
                pushToast(ketQua?.message, "warn");
            }
        }
        // pushLoading(false);
    }, 500)

    const totalGamePrematchSoccer = useRef<number>()

    useEffect(() => {
        var endday = new Date()
        endday.setHours(23)
        endday.setMinutes(59)
        endday.setSeconds(59)
        if (listDateFilterSoccer && listDateFilterSoccer[0] && listDateFilterSoccer[0].value && listDateFilterSoccer[0].value === dateFilterSoccer) {
            const socketInstance = io(API_URL_SOCCER);
            setSocket(socketInstance);
            socketInstance.on('connect', () => {
                console.log('Connected to server prematch');
            });
            socketInstance.on('message-prematch-soccer', async (data) => {
                var dataFilter = data.filter((item: any) => (new Date(`${item.realKickoffTime.replace("T", " ")}-04:00`).getTime() <= endday.getTime()))
                // var dataFilter = data.filter((item: any) => (new Date(item.realKickoffTime).getDate() === new Date().getUTCDate()))
                const updateList = dataFilter.filter((bet: any) => (bet.tournament.tournamentName === tournament)).map((item: any) => ({
                    ...item,
                    oddsOld: listGameSoccer.filter((betOld: any) => (betOld.id === item.id))[0]?.odds,
                    showTime: new Date(item.showTime + " GMT+8").toLocaleDateString() + " " + new Date(item.showTime + " GMT+8").toLocaleTimeString(),
                    showTimeClient: new Date(item.showTime + " GMT+8"),
                    showTimeClientTest: new Date(new Date()),
                    subtractTimeClient: new Date(item.showTime + " GMT+8").getTime() - new Date(new Date()).getTime()
                }))
                setListGameSoccer(updateList)
                totalGamePrematchSoccer.current = data.length
                // setTotalGameSoccer?.(data.length)
            });
            return () => {
                if (socketInstance) {
                    socketInstance.disconnect();
                }
            };
        }
    }, [dateFilterSoccer]);
    const checkEndOfPrematch = useThrottle(() => {
        // if (keyBetTabsSoccer === '1') {
        if (listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer.length > 0 && item.tournament === listAddStrightBetsSlipSoccer[0].tournament) {
            const listGameIdSoccer = listGameSoccer.map(item => Number(item.id));
            listGameSoccer.map((item, index) => {
                if (item.subtractTimeClient <= 60000 && listGameIdSoccer.includes(Number(listAddStrightBetsSlipSoccer[0].idGame)) && Number(item.id) === Number(listAddStrightBetsSlipSoccer[0].idGame)) {
                    removeEndOfGamePrematch && removeEndOfGamePrematch(Number(item.id))
                }
            })
        }
        // } else {
        if (listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.length > 0 && item.tournament === listParlayBetsSlipSoccer[0].tournament) {
            const listGameIdSoccer = listGameSoccer.map(item => Number(item.id));
            listGameSoccer.map((item, index) => {
                listParlayBetsSlipSoccer && listParlayBetsSlipSoccer.map((itemParlay, indexParlay) => {
                    if (item.subtractTimeClient <= 60000 && listGameIdSoccer.includes(Number(itemParlay.idGame)) && Number(item.id) === Number(itemParlay.idGame)) {
                        removeEndOfGamePrematch && removeEndOfGamePrematch(Number(itemParlay.idGame))
                    }
                })

            })
        }
        // }
    }, 500)

    useEffect(() => {
        if (listChooseBet.length > 0 && listDateFilterSoccer && listDateFilterSoccer[0].value === dateFilterSoccer) {
            checkEndOfPrematch()
        }
        if (typeListGameSoccer === 'select') {
            setTotalGameSoccer?.(listGameSoccer.length)
        } else {
            if (totalGamePrematchSoccer.current)
                setTotalGameSoccer?.(totalGamePrematchSoccer.current)
        }
    }, [listGameSoccer])

    useEffect(() => {
        if (resetBetGame) {
            gGameSoccerByTournamentName()
            updateBetGameReset?.()
        }
    }, [resetBetGame])

    useEffect(() => {
        if (item.key)
            gGameSoccerByTournamentName()
    }, [item])

    useEffect(() => {
        if (loadTournament && loadTournament === item.tournament) {
            gGameSoccerByTournamentName()
            setLoadTournament && setLoadTournament()
        }
    }, [loadTournament])
    return (
        <>

            {
                listGameSoccer && listGameSoccer.map((item, i) =>
                    <LazyLoad height={20} key={i} className='xs:hover:bg-transparentnone md:hover:bg-[#181c2a]'>
                        <div key={i} className='flex flex-col'>
                            {item.subtractTimeClient > 60000 && (
                                <div>
                                    <div className='flex w-full  border-dashed border-b border-[#202531] pt-[5px]'>
                                        <div className="lg:w-[140px] xs:hidden md:block">
                                            <div className="flex flex-col gap-1  ">
                                                <div className="w-[140px] flex flex-col items-start gap-0 pr-1">
                                                    <div className='flex items-center gap-2'>
                                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                        </div>
                                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-left'>{item.homeTeam.teamName}</span>
                                                    </div>
                                                    <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                        </div>
                                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-left'>{item.awayTeam.teamName}</span>
                                                    </div>
                                                </div>
                                                <div className="w-[140px] flex flex-col items-start gap-2 p-3 pl-1">
                                                    <div>
                                                        <img src={video_2} alt="" />
                                                    </div>
                                                    <span className="text-xs text-start text-[#E4E7F1] font-medium ">{item.showTime.split(" ")[0]}</span>
                                                    <span className="text-xs text-start text-[#E4E7F1] font-medium ">{`${item.showTime.split(" ")[1]} ${item.showTime.split(" ")[2] ? item.showTime.split(" ")[2] : ""}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full flex flex-col xs:gap-3 md:gap-0'>
                                            <div className="xs:flex md:hidden flex-col gap-3 pt-1">
                                                <div className="flex  items-center justify-center gap-4 w-full">
                                                    <div className='flex items-center gap-2'>
                                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                        </div>
                                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-left'>{item.homeTeam.teamName}</span>
                                                    </div>
                                                    <span className='text-xs text-[#D4B962] pl-2'>VS</span>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531] flex-shrink-0'>
                                                            <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                                                        </div>
                                                        <span className='text-[#E4E7F1] fw-[500] xs:text-xs lg:text-sm xl:text-sm xl2:text-[16px] xl3:text-[16px] text-left'>{item.awayTeam.teamName}</span>
                                                    </div>
                                                </div>
                                                <div className='flex items-center justify-center gap-6 border-time-live rounded-xl w-full'>
                                                    <div>
                                                        <img src={video_2} alt="" />
                                                    </div>
                                                    <div className='w-[18px] border border-[#D4B962]'></div>
                                                    <span className="text-xs text-start text-[#E4E7F1] font-medium ">{item.showTime.split(" ")[0]}</span>
                                                    <div className='w-[18px] border border-[#D4B962]'></div>
                                                    <span className="text-xs text-start text-[#E4E7F1] font-medium ">{`${item.showTime.split(" ")[1]} ${item.showTime.split(" ")[2] ? item.showTime.split(" ")[2] : ""}`}</span>
                                                </div>
                                            </div>
                                            {item.subtractTimeClient > 60000 && (
                                                <div className="gameDetail soccerGame flex flex-col w-full justify-between pl-6 !p-0">
                                                    <SoccerTopV3New
                                                        idGame={item.id}
                                                        homeTeam={item.homeTeam.teamName}
                                                        awayTeam={item.awayTeam.teamName}
                                                        tournament={tournament}
                                                        item={item}
                                                        listChooseBet={listChooseBet}
                                                        chooseBetSoccer={chooseBetSoccer}
                                                        dateFilterSoccer={dateFilterSoccer}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        checkUpdateOdds={checkUpdateOdds}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        listDateFilterSoccer={listDateFilterSoccer}
                                                    />
                                                </div >
                                            )}
                                        </div>
                                        {/* <div className="flex flex-col ">
                                            <span className="text-sm text-[#E4E7F1] font-bold-GT-America uppercase border-b border-b-[#1D212D] py-2 whitespace-nowrap">More bet</span>
                                            <div className="flex items-center justify-center h-full">
                                                <button
                                                    className="border-[6px] border-[#353229] rounded-[120px] h-[50px] w-[34px] flex flex-col justify-center items-center gap-1 bg-[#DBBF64] "
                                                    onClick={() => setShowMoreBet(!showMoreBet)}
                                                >
                                                    <span className="text-xs font-bold-GT-America text-[#131620]">{item.countNumberSubbet ? `+${item.countNumberSubbet}` : ""}</span>
                                                    <img src={`${showMoreBet ? up_icon : down_icon}`} alt="" />
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                        </div>

                    </LazyLoad>
                )
            }
            {

                checkNoData(listGameSoccer, 'pb-4')

            }

        </>

    );
};

export default GameSoccer_V2New;