/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import useDebounce from '../../../../../hook/useDebounce';
import StatusBarSoccerMoreTab from './StatusBarSoccerMoreTab';
import StatusBarSoccerCorrectScore from './StatusBarSoccerCorrectScore';
import StatusBarSoccerCorrectScoreFH from './StatusBarSoccerCorrectScore_FH';
import io from 'socket.io-client';
import {
    LockOutlined,
} from '@ant-design/icons';
import { API_URL_SOCCER } from '../../../../../env';
import StatusBarSoccerTotalCorners from './StatusBarSoccerTotalCorners';
import SoccerTopHDPFNew from './SoccerTopHDPFNew';
import SoccerTopOUFNew from './SoccerTopOUFNew';
import SoccerTop1x2F_V2New from './SoccerTop1x2FNew';
import SoccerTopHDPHNew from './SoccerTopHDPHNew';
import SoccerTopOUHNew from './SoccerTopOUHNew';
import SoccerTop1x2HNew from './SoccerTop1x2HNew';
import { down_icon, up_icon } from '../../../../../components/imgExport';

interface SoccerTopV2Props {
    period?: number | string
    idGame?: number | string
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    homeScoreTotalCorner?: number
    awayScoreTotalCorner?: number
    tournament: string
    item: homePage_V2Api.InfoGameSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    blockAllOdds?: boolean
    hiddenFirstHalf?: boolean
    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeScore?: number, awayScore?: number
    ) => void
    checkUpdateOdds?: (id: number, point: any, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number, color?: string) => void
}
const SoccerTopLiveV3New: React.FC<SoccerTopV2Props> = ({
    period, idGame, homeTeam, awayTeam, tournament, item, listChooseBet, listAddStrightBetsSlipSoccer, homeScore, awayScore, homeScoreTotalCorner, awayScoreTotalCorner, blockAllOdds, hiddenFirstHalf,
    chooseBetSoccer, checkUpdateOdds, checkUpdateStrightBetsSlip
}) => {
    const [socket, setSocket] = useState<any>(null);
    const updateOdds = useRef<any[]>([]);
    useEffect(() => {
        if (updateOdds.current) {
            listChooseBet.forEach(elementChooseBet => {
                updateOdds.current.forEach(elementUpdateOdd => {
                    if (elementChooseBet.id === elementUpdateOdd.id) {
                        checkUpdateOdds?.(elementUpdateOdd.id, elementUpdateOdd.point, elementUpdateOdd.prices)
                    }
                });
            });
        }

    }, [updateOdds.current])


    useEffect(() => {


        // var dataOld: any
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);

        socketInstance.on('connect', () => {
            console.log('Connected to server live');
        });
        socketInstance.emit("gameId-soccer", Number(item.id));

        socketInstance.on('message-soccer', async (data) => {
            updateOdds.current = data.payload?.data?.eventOdds?.updated
            if (data.length > 0) {
                let gameSubbet = data.filter((itemData: any) => (itemData.id === item.id))?.[0]?.odds.filter((item: any) => (item.marketType === "OddEven" || item.marketType === "FH_CorrectScore" || item.marketType === "TotalGoal" || item.marketType === "DoubleChance" || item.marketType === "CorrectScore"))
                let gameSubbetTotalCorners = data.filter((itemData: any) => (itemData.id === item.id))?.[0]?.odds.filter((item: any) => (item.marketType === "TotalCorners_FH_OverUnder" || item.marketType === "TotalCorners_FH_Handicap" || item.marketType === "TotalCorners_OverUnder" || item.marketType === "TotalCorners_Handicap"))
                let filterSubbet: any[] = []
                if (gameSubbet && gameSubbet.length) {
                    filterSubbet = Array.from(new Map(gameSubbet.map((item: any) => [item.marketType, item])).values());
                }
                setCountNumberSubbet(filterSubbet?.length ? (gameSubbetTotalCorners.length ? Number(filterSubbet?.length) + 1 : filterSubbet?.length) : 0)
            }
        });
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, []);

    const pushToast = useToast();

    const chooseBetSoccerTopHDPF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime HDP", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTopOUF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime OU", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTop1x2F = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime 1x2", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerTopHDPH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half HDP", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTopOUH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half OU", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTop1x2H = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half 1x2", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerOEF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "OddEven", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerTotalGoal = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Total Goal", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerDoubleChance = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Double Chance", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerCorrectScore = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Correct Score", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerCorrectScoreFH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Correct Score", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    // const chooseBetSoccerHTFT = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
    //     chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Half Time/Full Time", item.showTime, option, true)
    // }

    // const chooseBetSoccerFGLG = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
    //     chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Goal/Last Goal", item.showTime, option, true)
    // }

    const chooseBetSoccerTotalCornersOverUnder = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners OU", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }

    const chooseBetSoccerTotalCornersHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners HDP", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }


    const chooseBetTopTotalCornersFHHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners HDP", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }

    const chooseBetTopTotalCornersFHOU = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners OU", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }


    const [oddTopHDPF, setOddTopHDPF] = useState<any[]>([])
    const [oddTopOUF, setOddTopOUF] = useState<any[]>([])
    const [oddTop1X2, setOddTop1X2] = useState<any[]>([])
    const [oddEven, setOddEven] = useState<any[]>([])

    const [oddTopHDPFH, setOddTopHDPFH] = useState<any[]>([])
    const [oddTopOUFH, setOddTopOUFH] = useState<any[]>([])
    const [oddTop1X2FH, setOddTop1X2FH] = useState<any[]>([])

    const [totalGoal, setTotalGoal] = useState<any[]>([])
    const [doubleChance, setDoubleChance] = useState<any[]>([])
    const [correctScore, setCorrectScore] = useState<any[]>([])
    const [correctScoreFH, setCorrectScoreFH] = useState<any[]>([])
    const [HTFT, setHTFT] = useState<any[]>([])
    const [FGLG, setFGLG] = useState<any[]>([])

    const [totalCornersOverUnder, setTotalCornersOverUnder] = useState<any[]>([])
    const [totalCornersHandicap, setTotalCornersHandicap] = useState<any[]>([])
    const [totalCornersFHOverUnder, setTotalCornersFHOverUnder] = useState<any[]>([])
    const [totalCornersFHHandicap, setTotalCornersFHHandicap] = useState<any[]>([])



    const [oddTopHDPFOld, setOddTopHDPFOld] = useState<any[]>([])
    const [oddTopOUFOld, setOddTopOUFOld] = useState<any[]>([])
    const [oddTop1X2Old, setOddTop1X2Old] = useState<any[]>([])

    const [oddTopHDPFHOld, setOddTopHDPFHOld] = useState<any[]>([])
    const [oddTopOUFHOld, setOddTopOUFHOld] = useState<any[]>([])
    const [oddTop1X2FHOld, setOddTop1X2FHOld] = useState<any[]>([])

    const [oddEvenOld, setOddEvenOld] = useState<any[]>([])
    const [totalGoalOld, setTotalGoalOld] = useState<any[]>([])
    const [doubleChanceOld, setDoubleChanceOld] = useState<any[]>([])
    const [correctScoreOld, setCorrectScoreOld] = useState<any[]>([])
    const [correctScoreFHOld, setCorrectScoreFHOld] = useState<any[]>([])

    const [totalCornersOverUnderOld, setTotalCornersOverUnderOld] = useState<any[]>([])
    const [totalCornersHandicapOld, setTotalCornersHandicapOld] = useState<any[]>([])
    const [totalCornersFHOverUnderOld, setTotalCornersFHOverUnderOld] = useState<any[]>([])
    const [totalCornersFHHandicapOld, setTotalCornersFHHandicapOld] = useState<any[]>([])

    const [countNumberSubbet, setCountNumberSubbet] = useState<number>(0)
    const [showMoreBet, setShowMoreBet] = useState(false)
    //Soccer
    const gOddsByTournamentId = useDebounce(async () => {
        // pushLoading(true);
        const ketQua = await homePage_V2Api.getOddsSoccerByGameId(item.id);
        if (ketQua?.success) {
            const SubbetTotalCorners = ketQua.data.filter((item) => (item.marketType === "TotalCorners_FH_OverUnder" || item.marketType === "TotalCorners_FH_Handicap" || item.marketType === "TotalCorners_OverUnder" || item.marketType === "TotalCorners_Handicap"))
            const Subbet = ketQua.data.filter((item) => (item.marketType === "OddEven" || item.marketType === "FH_CorrectScore" || item.marketType === "TotalGoal" || item.marketType === "DoubleChance" || item.marketType === "CorrectScore"))
            const filterSubbet: any[] = Array.from(new Map(Subbet.map(item => [item.marketType, item])).values());
            setCountNumberSubbet(SubbetTotalCorners.length ? filterSubbet.length + 1 : filterSubbet.length)
            const Handicap = ketQua.data.filter((item) => item.marketType === "Handicap")
            Handicap.forEach(subArray => {
                subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
            });
            setOddTopHDPF(Handicap);

            const OverUnder = ketQua.data.filter((item) => item.marketType === "OverUnder")
            OverUnder.forEach(subArray => {
                subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
            });
            setOddTopOUF(OverUnder);
            var _1X2: any = ketQua.data.filter((item) => item.marketType === "_1X2")

            let _1X2Filter = _1X2
            if (_1X2Filter && _1X2Filter.length > 0) {
                var option1 = _1X2?.[0]?.prices?.filter((item: any) => item.option === "1")
                var option2 = _1X2?.[0]?.prices?.filter((item: any) => item.option === "2")
                var optionX = _1X2?.[0]?.prices?.filter((item: any) => item.option === "X")
                _1X2Filter[0].prices = [option1?.[0], option2?.[0], optionX?.[0]]
            }
            setOddTop1X2(_1X2Filter);
            const OddEven = ketQua.data.filter((item) => item.marketType === "OddEven")
            setOddEven(OddEven);

            if (!hiddenFirstHalf || hiddenFirstHalf === undefined) {
                const FH_Handicap = ketQua.data.filter((item) => item.marketType === "FH_Handicap")
                FH_Handicap.forEach(subArray => {
                    subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
                });
                setOddTopHDPFH(FH_Handicap);
                const FH_OverUnder = ketQua.data.filter((item) => item.marketType === "FH_OverUnder")
                FH_OverUnder.forEach(subArray => {
                    subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
                });
                setOddTopOUFH(FH_OverUnder);
                const FH_1X2 = ketQua.data.filter((item) => item.marketType === "FH_1X2")
                // setOddTop1X2FH(FH_1X2);
                let FH_1X2Filter = FH_1X2
                if (FH_1X2Filter && FH_1X2Filter.length > 0) {
                    var optionFH1 = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "1")
                    var optionFH2 = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "2")
                    var optionFHX = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "X")
                    FH_1X2Filter[0].prices = [optionFH1?.[0], optionFH2?.[0], optionFHX?.[0]]
                }
                setOddTop1X2FH(FH_1X2Filter);

                const correctScoreFH = ketQua.data.filter((item) => item.marketType === "FH_CorrectScore")
                setCorrectScoreFH(correctScoreFH);

                const TotalCornersFHOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_OverUnder")
                setTotalCornersFHOverUnder(TotalCornersFHOverUnderParam);

                const TotalCornersFHHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_Handicap")
                setTotalCornersFHHandicap(TotalCornersFHHandicapParam);
            } else {
                setOddTopHDPFH([])
                setOddTopOUFH([])
                setOddTop1X2FH([])
                setCorrectScoreFH([])
                setTotalCornersFHOverUnder([])
                setTotalCornersFHHandicap([])
            }
            const TotalGoal = ketQua.data.filter((item) => item.marketType === "TotalGoal")
            setTotalGoal(TotalGoal);
            const DoubleChance = ketQua.data.filter((item) => item.marketType === "DoubleChance")
            setDoubleChance(DoubleChance);
            const CorrectScore = ketQua.data.filter((item) => item.marketType === "CorrectScore")
            setCorrectScore(CorrectScore);
            const HTFTParam = ketQua.data.filter((item) => item.marketType === "HTFT")
            setHTFT(HTFTParam);
            const FGLGParam = ketQua.data.filter((item) => item.marketType === "FGLG")
            setFGLG(FGLGParam);

            const TotalCornersOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_OverUnder")
            setTotalCornersOverUnder(TotalCornersOverUnderParam);

            const TotalCornersHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_Handicap")
            setTotalCornersHandicap(TotalCornersHandicapParam);

        } else {
            // setListGameSoccer([]);
            pushToast(ketQua?.message, "warn");
        }
        // pushLoading(false);
    }, 1000)

    useEffect(() => {
        if (item.id)
            gOddsByTournamentId()
    }, [])

    useEffect(() => {
        setOddTopHDPFOld(oddTopHDPF)
        setOddTopHDPF(item.odds?.filter((item) => item.marketType === "Handicap") ? item.odds?.filter((item) => item.marketType === "Handicap") : [])

        setOddTopOUFOld(oddTopOUF)
        setOddTopOUF(item.odds?.filter((item) => item.marketType === "OverUnder") ? item.odds?.filter((item) => item.marketType === "OverUnder") : [])

        setOddTop1X2Old(oddTop1X2)
        setOddTop1X2(item.odds?.filter((item) => item.marketType === "_1X2") ? item.odds?.filter((item) => item.marketType === "_1X2") : [])

        if (!hiddenFirstHalf || hiddenFirstHalf === undefined) {
            setOddTopHDPFHOld(oddTopHDPFH)
            setOddTopHDPFH(item.odds?.filter((item) => item.marketType === "FH_Handicap") ? item.odds?.filter((item) => item.marketType === "FH_Handicap") : [])

            setOddTopOUFHOld(oddTopOUFH)
            setOddTopOUFH(item.odds?.filter((item) => item.marketType === "FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "FH_OverUnder") : [])

            setOddTop1X2FHOld(oddTop1X2FH)
            setOddTop1X2FH(item.odds?.filter((item) => item.marketType === "FH_1X2") ? item.odds?.filter((item) => item.marketType === "FH_1X2") : [])

            setCorrectScoreFHOld(correctScoreFH)
            setCorrectScoreFH(item.odds?.filter((item) => item.marketType === "FH_CorrectScore") ? item.odds?.filter((item) => item.marketType === "FH_CorrectScore") : [])

            setTotalCornersFHOverUnderOld(totalCornersFHOverUnder)
            setTotalCornersFHOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") : [])

            setTotalCornersFHHandicapOld(totalCornersFHHandicap)
            setTotalCornersFHHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") : [])
        } else {
            setOddTopHDPFHOld([])
            setOddTopHDPFH([])
            setOddTopOUFHOld([])
            setOddTopOUFH([])
            setOddTop1X2FHOld([])
            setOddTop1X2FH([])
            setCorrectScoreFHOld([])
            setCorrectScoreFH([])
            setTotalCornersFHOverUnder([])
            setTotalCornersFHHandicap([])
        }

        setOddEvenOld(oddEven)
        setOddEven(item.odds?.filter((item) => item.marketType === "OddEven") ? item.odds?.filter((item) => item.marketType === "OddEven") : [])

        setTotalGoalOld(totalGoal)
        setTotalGoal(item.odds?.filter((item) => item.marketType === "TotalGoal") ? item.odds?.filter((item) => item.marketType === "TotalGoal") : [])

        setDoubleChanceOld(doubleChance)
        setDoubleChance(item.odds?.filter((item) => item.marketType === "DoubleChance") ? item.odds?.filter((item) => item.marketType === "DoubleChance") : [])

        setCorrectScoreOld(correctScore)
        setCorrectScore(item.odds?.filter((item) => item.marketType === "CorrectScore") ? item.odds?.filter((item) => item.marketType === "CorrectScore") : [])

        setTotalCornersOverUnderOld(totalCornersOverUnder)
        setTotalCornersOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") : [])

        setTotalCornersHandicapOld(totalCornersHandicap)
        setTotalCornersHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") : [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])
    return (
        <>
            <div className='flex borderLine detailTop'>
                <div className='pb-[5px] w-full'>
                    <div>
                        {blockAllOdds ?
                            <>
                                <div className='flex flex-col w-full'>
                                    <div className="gameRatio flex w-full gap-1">
                                        <div className="homeRaito w-full flex xs:flex-col md:flex-row justify-between gap-1">
                                            <div className="xs:flex md:hidden gap-1 w-full ">
                                                <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    FT Handicap
                                                </span>
                                                <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    FT o/u
                                                </span>
                                                <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    FT 1X2
                                                </span>
                                            </div>
                                            <div className='xs:grid md:flex grid-cols-3 xs:w-full md:w-[48%] justify-between gap-1'>
                                                <div className={`w-full  flex flex-col justify-between a1x2`}>
                                                    <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                        <div className='w-full flex flex-col hdp'>
                                                            <div className={`justify-center w-full flex items-center `}>
                                                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                    <div className={`relative py-1 w-full flex justify-center`}>
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`w-full  flex flex-col justify-between a1x2`}>
                                                    <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                        <div className='w-full flex flex-col hdp'>
                                                            <div className={`justify-center w-full flex items-center `}>
                                                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                    <div className={`relative py-1 w-full flex justify-center`}>
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`w-full  flex flex-col justify-between a1x2`}>
                                                    <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                        <div className='w-full flex flex-col hdp'>
                                                            <div className={`justify-center w-full flex items-center `}>
                                                                <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                    <div className={`relative py-1 w-full flex justify-center`}>
                                                                        <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {period && (Number(period) === 5 || Number(period) === 2) && window.outerWidth < 1365 ?
                                                ""
                                                :
                                                <>
                                                    <div className="xs:flex md:hidden gap-1 w-full border-t border-t-[#1D212D]">
                                                        <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                            Fh Handicap
                                                        </span>
                                                        <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                            Fh o/u
                                                        </span>
                                                        <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                            Fh 1X2
                                                        </span>
                                                    </div>
                                                    <div className='xs:grid md:flex grid-cols-3 justify-between xs:w-full md:w-[48%] gap-1'>
                                                        <div className={`w-full flex flex-col justify-between a1x2`}>
                                                            <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                                <div className='w-full flex flex-col hdp'>
                                                                    <div className={`justify-center w-full flex items-center `}>
                                                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`w-full  flex flex-col justify-between a1x2`}>
                                                            <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                                <div className='w-full flex flex-col hdp'>
                                                                    <div className={`justify-center w-full flex items-center `}>
                                                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`w-full  flex flex-col justify-between a1x2`}>
                                                            <div className={`flex justify-between w-full xs:h-auto md:h-[100px] items-center `}>
                                                                <div className='w-full flex flex-col hdp'>
                                                                    <div className={`justify-center w-full flex items-center `}>
                                                                        <div className={`h-[30px] ratioDetail ratioDetailSoccer !m-0 border border-[#565F73] flex justify-center w-full rounded-md relative`}>
                                                                            <div className={`relative py-1 w-full flex justify-center`}>
                                                                                <p className='!text-[#E4E7F1]'><LockOutlined /> </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }


                                            <div className="w-[4%] xs:hidden md:flex flex-col pr-1">
                                                <div className="flex items-center justify-end h-full">
                                                    <button
                                                        className="border-[6px] border-[#353229] rounded-[120px] h-[50px] w-[34px] flex flex-col justify-end items-center gap-1 bg-[#DBBF64] "
                                                    >
                                                        <span className="text-xs font-bold-GT-America text-[#131620]">0</span>
                                                        <img src={`${showMoreBet ? up_icon : down_icon}`} alt="" className='pb-1' />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='flex flex-col w-full'>
                                <div className="gameRatio flex xs:flex-col md:flex-row w-full gap-1 justify-between">
                                    <div className="xs:flex md:hidden gap-1 w-full ">
                                        <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                            FT Handicap
                                        </span>
                                        <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                            FT o/u
                                        </span>
                                        <span className='w-[33%] pb-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                            FT 1X2
                                        </span>
                                    </div>
                                    <div className="homeRaito xs:w-full md:w-[48%] flex justify-between gap-1">
                                        {/* <SoccerTopA1x2F_V2 /> */}
                                        <SoccerTopHDPFNew
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopHDPF}
                                            oddsOld={oddTopHDPFOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopHDPF}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTopOUFNew
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopOUF}
                                            oddsOld={oddTopOUFOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopOUF}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTop1x2F_V2New
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTop1X2}
                                            oddsOld={oddTop1X2Old}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTop1x2F}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                    </div>
                                    {period && (Number(period) === 5 || Number(period) === 2) && window.outerWidth < 1365 ?
                                        ""
                                        :
                                        <>
                                            <div className="xs:flex md:hidden gap-1 w-full border-t border-t-[#1D212D]">
                                                <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    Fh Handicap
                                                </span>
                                                <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    Fh o/u
                                                </span>
                                                <span className='w-[33%] py-2 uppercase text-[13px] text-[#E4E7F1] font-medium border-b border-b-[#1D212D] flex justify-center'>
                                                    Fh 1X2
                                                </span>
                                            </div>
                                            <div className="awayRaito xs:w-full md:w-[48%] flex justify-between gap-1">
                                                <SoccerTopHDPHNew
                                                    homeTeam={homeTeam}
                                                    awayTeam={awayTeam}
                                                    homeScore={homeScore ? Number(homeScore) : 0}
                                                    awayScore={awayScore ? Number(awayScore) : 0}
                                                    item={oddTopHDPFH}
                                                    oddsOld={oddTopHDPFHOld}
                                                    listChooseBet={listChooseBet}
                                                    listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                    chooseBetTop={chooseBetSoccerTopHDPH}
                                                    checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                    updateOdds={updateOdds.current}
                                                />
                                                <SoccerTopOUHNew
                                                    homeTeam={homeTeam}
                                                    awayTeam={awayTeam}
                                                    homeScore={homeScore ? Number(homeScore) : 0}
                                                    awayScore={awayScore ? Number(awayScore) : 0}
                                                    item={oddTopOUFH}
                                                    oddsOld={oddTopOUFHOld}
                                                    listChooseBet={listChooseBet}
                                                    listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                    chooseBetTop={chooseBetSoccerTopOUH}
                                                    checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                    updateOdds={updateOdds.current}
                                                />
                                                <SoccerTop1x2HNew
                                                    homeTeam={homeTeam}
                                                    awayTeam={awayTeam}
                                                    homeScore={homeScore ? Number(homeScore) : 0}
                                                    awayScore={awayScore ? Number(awayScore) : 0}
                                                    item={oddTop1X2FH}
                                                    oddsOld={oddTop1X2FHOld}
                                                    listChooseBet={listChooseBet}
                                                    listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                    chooseBetTop={chooseBetSoccerTop1x2H}
                                                    checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                    updateOdds={updateOdds.current}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="w-[4%] xs:hidden md:flex flex-col pr-1">
                                        <div className="flex items-center justify-end h-full">
                                            <button
                                                className="border-[6px] border-[#353229] rounded-[120px] h-[50px] w-[34px] flex flex-col justify-center items-center gap-1 bg-[#DBBF64] "
                                                onClick={() => countNumberSubbet ? setShowMoreBet(!showMoreBet) : ""}
                                            >
                                                <span className="text-xs font-bold-GT-America text-[#131620]">{countNumberSubbet ? `+${countNumberSubbet}` : "0"}</span>
                                                <img src={`${showMoreBet ? up_icon : down_icon}`} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className='xs:block md:hidden w-full rounded border border-[#212531] py-2 text-sm text-[#C1C9E1]'
                                        onClick={() => setShowMoreBet(!showMoreBet)}
                                    >
                                        View {!showMoreBet ? `more (${countNumberSubbet})` : 'less'}
                                    </button>
                                </div>
                                <div className={`transition-opacity duration-200 ease-in-out overflow-hidden xs:pr-0 md:pr-10 ${showMoreBet ? ' opacity-100' : ' opacity-0'}`}>
                                    {
                                        showMoreBet && (
                                            <div className="gameRatio flex">
                                                <div className="homeRaito flex flex-col w-full">
                                                    <StatusBarSoccerMoreTab
                                                        oddsEven={oddEven}
                                                        oddsEvenOld={oddEvenOld}
                                                        totalGoal={totalGoal}
                                                        totalGoalOld={totalGoalOld}
                                                        doubleChance={doubleChance}
                                                        doubleChanceOld={doubleChanceOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        homeScore={homeScore ? Number(homeScore) : 0}
                                                        awayScore={awayScore ? Number(awayScore) : 0}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerTotalGoal}
                                                        chooseBetTopDoubleChance={chooseBetSoccerDoubleChance}
                                                        chooseBetSoccerOEF={chooseBetSoccerOEF}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        updateOdds={updateOdds.current}
                                                    />
                                                    <StatusBarSoccerCorrectScoreFH
                                                        correctScoreFH={correctScoreFH}
                                                        correctScoreFHOld={correctScoreFHOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        homeScore={homeScore ? Number(homeScore) : 0}
                                                        awayScore={awayScore ? Number(awayScore) : 0}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerCorrectScoreFH}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        updateOdds={updateOdds.current}
                                                    />
                                                    <StatusBarSoccerCorrectScore
                                                        correctScore={correctScore}
                                                        correctScoreOld={correctScoreOld}
                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        homeScore={homeScore ? Number(homeScore) : 0}
                                                        awayScore={awayScore ? Number(awayScore) : 0}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerCorrectScore}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        updateOdds={updateOdds.current}
                                                    />

                                                    <StatusBarSoccerTotalCorners
                                                        totalGoal={totalGoal}
                                                        totalGoalOld={totalGoalOld}

                                                        totalCornersOverUnder={totalCornersOverUnder}
                                                        totalCornersHandicap={totalCornersHandicap}
                                                        totalCornersFHOverUnder={totalCornersFHOverUnder}
                                                        totalCornersFHHandicap={totalCornersFHHandicap}
                                                        totalCornersOverUnderOld={totalCornersOverUnderOld}
                                                        totalCornersHandicapOld={totalCornersHandicapOld}
                                                        totalCornersFHOverUnderOld={totalCornersFHOverUnderOld}
                                                        totalCornersFHHandicapOld={totalCornersFHHandicapOld}

                                                        homeTeam={homeTeam}
                                                        awayTeam={awayTeam}
                                                        homeScoreTotalCorner={homeScoreTotalCorner}
                                                        awayScoreTotalCorner={awayScoreTotalCorner}
                                                        listChooseBet={listChooseBet}
                                                        listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                        chooseBetTop={chooseBetSoccerTotalCornersOverUnder}
                                                        chooseBetTopTotalCornersHandicap={chooseBetSoccerTotalCornersHandicap}
                                                        chooseBetTopTotalCornersFHHandicap={chooseBetTopTotalCornersFHHandicap}
                                                        chooseBetTopTotalCornersFHOU={chooseBetTopTotalCornersFHOU}
                                                        checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                        updateOdds={updateOdds.current}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>


                            </div>
                        }


                    </div>
                </div>
            </div >
        </>
    );
};

export default SoccerTopLiveV3New;