/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectorUser, setUserMoney } from '../../../../../store/slices/userSlice';
import { formatPrice } from '../../../../../until';
import LoadingModal from '../../../../../components/Modal/LoadingModal';
import WithDraw from '../../../../../components/Modal/WithDrawModal';
import { getPresentMoney, getRefundMoney } from '../../../../../api/billAcceptor/billAcceptorApi';
import useToast from '../../../../../hook/useToast';
import ModalWarning from '../../../../../components/Modal/ModalWarning';
import { TransactionWhite } from '../../../../../components/imgExport';
import useThrottle from '../../../../../hook/useThrottle';
var intervalMoney: any

const HeadRight: React.FC = () => {
  const { userId, presentMoney } = useSelector(selectorUser);
  const pushToast = useToast();
  const dispatch = useDispatch();

  const [money, setMoney] = useState("")
  const [billCode, setBillCode] = useState("")
  const [showLoading, setShowLoading] = useState(false)
  const [showWithDraw, setWithDraw] = useState(false)
  const [showModalWarning, setShowModalWarning] = useState(false)
  const [time, setTime] = useState(0)


  const getCurrentMoney = useThrottle(async () => {
    // pushLoading(true);
    var timeTotal = 10
    const interval = setInterval(() => {
      if (timeTotal > 0) {
        timeTotal -= 1
        setTime(timeTotal)
      } else {
        clearInterval(interval)
      }
    }, 1000);
    const ketQua = await getPresentMoney();
    if (ketQua?.success) {
      dispatch(setUserMoney(ketQua.data.currentMoney))
      setMoney(ketQua.data.currentMoney)
    }
    // setTimeout(() => {
    //   pushLoading(false);
    // }, 1000)
  }, 10000)

  const handelConfirm = async () => {
    setWithDraw(false)
    setShowLoading(true);
    if (Number(presentMoney) > 0) {
      const ketQua = await getRefundMoney(userId, presentMoney);
      if (ketQua?.success) {
        // logOut()
        await setBillCode(ketQua.data.billCode)
        await setMoney(presentMoney)
        handleResetMoney()
      } else {
        if (ketQua.type === "nem") {
          dispatch(setUserMoney(ketQua.data.presentMoney))
          setShowModalWarning(true)
        }
        pushToast(ketQua?.message, "warn");
      }
    }
    setShowLoading(false);
  }

  const handleResetMoney = () => {
    dispatch(setUserMoney("0"))
  };

  useEffect(() => {
    getCurrentMoney()
    intervalMoney = setInterval(() => {
      getCurrentMoney()
    }, 60000);
  }, [])

  return (
    <Flex gap="small" wrap="wrap" style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>

      <LoadingModal
        showModal={showLoading}
        setShowModal={setShowLoading}
      />

      <WithDraw
        showModal={showWithDraw}
        setShowModal={setWithDraw}
        handelConfirm={handelConfirm}
      />

      <ModalWarning
        showModal={showModalWarning}
        setShowModal={setShowModalWarning}
      />

      <span className='xs:text-[10px] xl:text-sm text-[#D4B962]'>
        ${formatPrice(presentMoney || 0)}
      </span>
      <div className='xs:hidden xl:flex items-center pl-1 cursor-pointer h-10 text-white  gap-2' onClick={() => getCurrentMoney()}>
        {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />}
        <span className=' lg:text-xs  xl2:text-sm  fw-[400] uppercase text-white h-fit'>Reload</span>
      </div>
    </Flex>
  );
}

export default HeadRight;

