import { Modal } from "antd"
import { useSelector } from "react-redux"
import { selectorUser } from "../../store/slices/userSlice"
import { formatPrice } from "../../until"
import { withdraw } from "../imgExport"

interface ModalBetsProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void
    handelConfirm: () => void
}
const WithDraw = ({ showModal, setShowModal, handelConfirm }: ModalBetsProps) => {
    const { presentMoney } = useSelector(selectorUser);
    return (

        <Modal
            className='canPlayModal withDrawModal flex justify-center'
            title={<img src={withdraw} alt="" />}
            open={showModal}
            onOk={handelConfirm}
            onCancel={() => setShowModal(false)}
            okText={<span className="text-[14px] font-[600] bg-[#D4B962] text-black">Withdraw</span>}
            cancelText={<span className="text-[14px] font-[600]">Cancel</span>}
        >
            <p className="text-center !text-[18px] text-[#D4B962] !pb-1">Withdraw money in wallet</p>
            <p className="!text-[14px] !pb-0">You have <span className="text-[#D4B962]">${formatPrice(presentMoney || 0)}</span>  left in your wallet.</p>
            <p className="!text-[14px] !pb-0">Do you want to continue betting or</p>
            <p className="!text-[14px] !pb-4">want to withdraw?</p>
        </Modal>
    )
}
export default WithDraw