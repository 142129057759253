/* eslint-disable import/no-anonymous-default-export */
const getToken = () => {
    return sessionStorage.getItem('token');
    // return localStorage.getItem('token');
}

const getTokenNew = () => {
    return '12345678-abcd-1234-ef00-1234567890ab';
}

const setToken = (token: string) => {
    sessionStorage.setItem('token', token);
    // localStorage.setItem('token', token);
}

const removeToken = () => {
    sessionStorage.removeItem('token');
    // localStorage.removeItem('token');
}

const setCodeConfirm = (code: string) => {
    sessionStorage.setItem('code', code);
    // localStorage.setItem('code', code);
}

const getCodeConfirm = () => {
    return sessionStorage.getItem('code');
    // return localStorage.getItem('code');
}

const removeCode = () => {
    sessionStorage.removeItem('code');
    // localStorage.removeItem('code');
}

const setEmailComfirm = (email: string) => {
    sessionStorage.setItem('email', email);
    // localStorage.setItem('email', email);
}

const getEmailComfirm = () => {
    return sessionStorage.getItem('email');
    // return localStorage.getItem('email');
}

const removeEmail = () => {
    sessionStorage.removeItem('email');
    // localStorage.removeItem('email');
}
export default {
    getToken, getTokenNew, setToken, removeToken, setCodeConfirm, getCodeConfirm, removeCode, setEmailComfirm, getEmailComfirm, removeEmail
}
